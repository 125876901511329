import { Action } from '@/constants'
// PREFIX: MODULE/VIEW
const PREFIX = 'EXAMPLE/REDUX';

// BUSINESS ACTIONS
const INCREMENT = `${PREFIX}/INCREMENT`;
const DECREMENT = `${PREFIX}/DECREMENT`;

// ACTION CREATER
const actions = {

  increment: (count: number) => ({
    type: INCREMENT,
    payload: count,
  }),

  decrement: (count: number) => ({
    type: DECREMENT,
    payload: count,
  })

};

// 定义 State 结构类型 
type StoreState = {
  count: number
};
const initData = {
  count: 0
}
const reducer = (state = initData, action: Action): StoreState => {
  switch (action.type) {
    case INCREMENT:
      return {
        ...state,
        count: action.payload
      }
    case DECREMENT:
      return {
        ...state,
        count: action.payload
      }
    default:
      return state
  }
}

export default reducer;
export { actions };
