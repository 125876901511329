import { routes as log } from 'modules/log'
import { routes as example } from 'modules/example'
import { gkptIcon, jsptIcon } from '@/config/iconsvg';

let urls: any[] = [
  {
    name: '结算平台',
    icon: jsptIcon,
    children: [
      {
        name: '收费主档管理',
        children: [
          {
            name: '收费项列表',
            exact: true,
            path: '/charge/master',
            crumbs: ['收费主档管理', '收费项列表']
          },
          {
            exact: true,
            path: '/charge/master/add',
            crumbs: ['收费主档管理', '收费项列表', '新增']
          }, {
            exact: true,
            path: '/charge/master/edit',
            crumbs: ['收费主档管理', '收费项列表', '编辑']
          }, {
            exact: true,
            path: '/charge/master/view',
            crumbs: ['收费主档管理', '收费项列表', '查看']
          }, {
            name: '收费标准模板查询',
            exact: true,
            path: '/charge/standard',
            crumbs: ['收费主档管理', '收费标准模板查询']
          }, {
            // name: '正常收费标准维护',
            exact: true,
            path: '/charge/standard/add/:type',
            crumbs: ['收费主档管理', '收费标准模板查询', '新增']
          }, {
            // name: '正常收费标准维护',
            exact: true,
            path: '/charge/standard/edit/:type',
            crumbs: ['收费主档管理', '收费标准模板查询', '编辑']
          }, {
            exact: true,
            path: '/charge/standard/view/:type',
            crumbs: ['收费主档管理', '收费标准模板查询', '查看']
          }, {
            name: '收费模式列表',
            exact: true,
            path: '/charge/model',
            crumbs: ['收费主档管理', '收费模式列表']
          }, {
            // name: '新增收费模式',
            exact: true,
            path: '/charge/model/add',
            crumbs: ['收费主档管理', '收费模式列表', '新增']
          }, {
            exact: true,
            path: '/charge/model/edit',
            crumbs: ['收费主档管理', '收费模式列表', '编辑']
          }, {
            // name: '收费模式查看',
            exact: true,
            path: '/charge/model/view',
            crumbs: ['收费主档管理', '收费模式列表', '查看']
          }, {
            exact: true,
            path: '/charge/model/modelstandard',
            crumbs: ['收费主档管理', '收费模式列表', '收费标准']
          }, {
            name: '预设收费标准条件',
            exact: true,
            path: '/charge/presetstandard',
            crumbs: ['收费主档管理', '预设收费标准条件']
          }, {
            name: '预估营业额',
            exact: true,
            path: '/charge/turnover',
            crumbs: ['收费主档管理', '预估营业额']
          }, {
            name: '收费比例报表',
            exact: true,
            path: '/charge/report',
            crumbs: ['收费主档管理', '收费比例报表']
          }, {
            name: '餐厅收费项清单',
            exact: true,
            path: '/charge/feelist',
            crumbs: ['收费主档管理', '餐厅收费项清单']
          }, {
            exact: true,
            path: '/charge/feelist/edit',
            crumbs: ['收费主档管理', '餐厅收费项清单', '编辑']
          }, {
            exact: true,
            path: '/charge/feelist/view',
            crumbs: ['收费主档管理', '餐厅收费项清单', '查看']
          },
          {
            name: '时间窗口设定',
            exact: true,
            path: '/charge/timesetting',
            crumbs: ['收费主档管理', '时间窗口设定']
          }
        ]
      },
      {
        name: '账单管理',
        path: '/bill',
        children: [
          {
            name: '手工账单',
            exact: true,
            path: '/bill/manualbill',
            crumbs: ['账单管理', '手工账单']
          },
          {
            exact: true,
            path: '/bill/manualbill/onefile',
            crumbs: ['账单管理', '手工账单维护']
          },
          {
            name: '加盟商付款账单',
            exact: true,
            path: '/bill/franchisees',
            crumbs: ['账单管理', '加盟商付款账单']
          },
          {
            name: '月度收费账单',
            exact: true,
            path: '/bill/monthlybill',
            crumbs: ['账单管理', '月度收费账单']
          },
          { // 月度收费账单查看
            exact: true,
            path: '/bill/monthlybill/onefile',
            crumbs: ['账单管理', '查看']
          },
          {
            name: '手工销账',
            exact: true,
            hasCatch: true,
            path: '/bill/chargeoffs',
            crumbs: ['账单管理', '手工销账']
          },
          {
            name: '申诉列表',
            exact: true,
            path: '/bill/appeal',
            crumbs: ['账单管理', '申诉列表']
          },
          {
            name: '营建工程账单',
            exact: true,
            path: '/bill/buildProject',
            crumbs: ['账单管理', '营建工程账单']
          },
          {
            name: 'JDE入账错误',
            exact: true,
            path: '/bill/jdeAccountError',
            crumbs: ['账单管理', 'JDE入账错误']
          },
          {
            name: 'JDE核销错误',
            exact: true,
            path: '/bill/jdeChargeoffError',
            crumbs: ['账单管理', 'JDE核销错误']
          },
          {
            name: 'JDE错误',
            exact: true,
            path: '/bill/jdeError',
            crumbs: ['账单管理', 'JDE错误']
          }
        ]
      },
      {
        name: '百胜支付账单',
        path: '/yumPaybill',
        children: [
          {
            name: '电商月度结算单',
            exact: true,
            path: '/yumPaybill/monthState',
            crumbs: ['百胜支付账单', '电商月度结算单']
          },
          {
            name: '礼品卡开票清单',
            exact: true,
            path: '/yumPaybill/giftCardBill',
            hasCatch: true,
            crumbs: ['百胜支付账单', '礼品卡开票清单']
          }, {
            name: '会员卡Prime开票清单',
            exact: true,
            path: '/yumPaybill/msCardBill',
            hasCatch: true,
            crumbs: ['百胜支付账单', '会员卡Prime开票清单']
          }, {
            name: '礼品卡核销明细',
            exact: true,
            path: '/yumPaybill/giftVerifdetail',
            hasCatch: true,
            crumbs: ['百胜支付账单', '礼品卡核销明细']
          }, {
            name: '会员卡Prime核销明细',
            exact: true,
            path: '/yumPaybill/vipVerifdetail',
            hasCatch: true,
            crumbs: ['百胜支付账单', '会员卡Prime核销明细']
          }, {
            name: '电子券码核销明细',
            exact: true,
            path: '/yumPaybill/electronicVerifdetail',
            hasCatch: true,
            crumbs: ['百胜支付账单', '电子券码核销明细']
          }, {
            name: '百胜付款明细上传',
            exact: true,
            path: '/yumPaybill/payDetailUpload',
            hasCatch: true,
            crumbs: ['百胜支付账单', '百胜付款明细上传']
          }, {
            name: '百胜付款明细查询',
            exact: true,
            path: '/yumPaybill/payDetailSearch',
            hasCatch: true,
            crumbs: ['百胜支付账单', '百胜付款明细查询']
          }
        ]
      },
      {
        name: '开票情况跟踪',
        path: '/billingTrack',
        children: [
          {
            name: '开票信息上传列表',
            exact: true,
            path: '/billingTrack/upload',
            crumbs: ['开票情况跟踪', '开票信息上传列表']
          }, {
            // name: '',
            exact: true,
            path: '/billingTrack/upload/oneFile',
            crumbs: ['开票情况跟踪', '开票信息上传列表']
          }, {
            name: '开票情况跟踪',
            exact: false,
            path: '/billingTrack/detail',
            crumbs: ['开票情况跟踪']
          }
        ]
      },
      {
        name: '欠款断供',
        path: '/debtOffStock',
        children: [
          {
            name: '逾期欠款汇总表',
            exact: true,
            path: '/debtOffStock/summary',
            crumbs: ['欠款断供', '逾期欠款汇总表']
          }, {
            // name: '欠款明细',
            exact: false,
            path: '/debtOffStock/summary/detail',
            crumbs: ['欠款断供']
          }
        ]
      },
      {
        name: 'JDE页面',
        children: [
          {
            name: 'JDE页面',
            exact: true,
            path: '/jdeManagement/jdeList',
            crumbs: ['JDE页面', 'JDE总表']
          },
        ]
      },
      {
        name: '基础数据配置',
        children: [
          {
            name: '基础数据码表(财务)',
            exact: true,
            path: '/basicData/codeCwTable',
            crumbs: ['基础数据配置', '基础数据码表(财务)']
          }, {
            name: '基础数据码表(业务)',
            exact: true,
            path: '/basicData/codeYwTable',
            crumbs: ['基础数据配置', '基础数据码表(业务)']
          }, {
            name: '租金收费开票单位维护',
            exact: true,
            path: '/basicData/rentcharge',
            crumbs: ['基础数据配置', '租金收费开票单位维护']
          }, {
            name: '餐厅属性码表维护',
            exact: true,
            path: '/basicData/restaurantattr',
            crumbs: ['基础数据配置', '餐厅属性码表维护']
          }, {
            name: 'YUM配销中心维护',
            exact: true,
            path: '/basicData/restaurantyum',
            crumbs: ['基础数据配置', 'YUM配销中心维护']
          }, {
            name: '维护号段',
            exact: true,
            path: '/basicData/jdecode',
            crumbs: ['基础数据配置', '维护号段']
          }
        ]
      },
      {
        name: '立项申请',
        children: [
          {
            name: '资产管理计划',
            exact: true,
            path: '/projectapply/assetplan',
            // hasCatch: true,
            crumbs: ['立项申请', '资产管理计划']
          }, {
            name: '资产管理审核',
            exact: true,
            path: '/projectapply/assetapply',
            // hasCatch: true,
            crumbs: ['立项申请', '资产管理审核']
          },
          {
            name: '我的餐厅',
            exact: true,
            path: '/projectapply/restaurant',
            crumbs: ['立项申请', '我的餐厅']
          }, {
            name: '资管信息',
            exact: true,
            path: '/projectapply/restaurant/changerecord',
            crumbs: ['立项申请', '资管信息']
          }, {
            name: '立项审批',
            exact: true,
            path: '/projectapply/approval',
            crumbs: ['立项申请', '立项审批']
          },
          {
            name: 'AOP计划',
            exact: true,
            path: '/projectapply/aopplan',
            crumbs: ['立项申请', 'AOP计划']
          }
        ]
      }
      // {
      //   name: '运维',
      //   path: '/opera',
      //   children: [
      //     {
      //       name: '招行对接白名单',
      //       exact: true,
      //       path: '/opera/whitelists',
      //       crumbs: ['运维', '招行对接白名单']
      //     },
      //     {
      //       name: '招行对接人员维护',
      //       exact: true,
      //       path: '/opera/personmainten',
      //       crumbs: ['运维', '招行对接人员维护']
      //     }
      //   ]
      // }
    ]
  }, {
    name: '管控平台',
    icon: gkptIcon,
    children: [
      {
        name: '收入申报',
        path: '/income',
        children: [
          {
            name: '收入申报填写',
            exact: true,
            path: '/income/declare',
            crumbs: ['收入申报', '收入申报填写']
          },
          {
            name: '收入申报收费试算',
            exact: true,
            path: '/income/trial',
            crumbs: ['收入申报', '收入申报收费试算']
          },
          {
            name: '收入申报历史查询',
            exact: true,
            path: '/income/history',
            crumbs: ['收入申报', '收入申报历史查询']
          },
          {
            name: '日营业额查询',
            exact: true,
            path: '/income/rerecord',
            crumbs: ['收入申报', '日营业额查询']
          },
          {
            name: '行销活动经费',
            exact: true,
            path: '/income/lsm',
            crumbs: ['账单管理', '行销活动经费']
          },
          { // LSM预算管理表
            exact: true,
            path: '/income/lsm/onefile',
            crumbs: ['账单管理', 'LSM预算管理表']
          },
        ]
      },
      {
        name: '加盟主档管理',
        // path: '/franchisees',
        children: [
          // {
          //   name: '加盟商付款账单',
          //   exact: true,
          //   path: '/franchisees/bills',
          // },
          {
            name: '加盟商列表',
            exact: true,
            path: '/franchisees/lists',
            crumbs: ['加盟主档管理', '加盟商列表']
          }, {
            exact: true,
            path: '/franchisees/lists/addperson',
            crumbs: ['加盟主档管理', '加盟商列表', '新增']
            // crumbs: { // 面包屑使用 满足场景 暂时使用这种方式
            //   title: '新增个人加盟商',
            //   parent: ['/franchisees/lists', '加盟商列表']
            // }
          }, {
            exact: true,
            path: '/franchisees/lists/addcompany',
            crumbs: ['加盟主档管理', '加盟商列表', '新增']
            // crumbs: { // 面包屑使用 满足场景 暂时使用这种方式
            //   title: '新增公司加盟商',
            //   parent: ['/franchisees/lists', '加盟商列表']
            // }
          }, {
            name: '加盟公司列表',
            exact: true,
            path: '/franchisees/company',
            crumbs: ['加盟主档管理', '加盟公司列表']
          }, { // 新增加盟公司
            exact: true,
            path: '/franchisees/company/addcompany',
            crumbs: ['加盟主档管理', '加盟公司']
          }, {
            name: '开票单位列表',
            exact: true,
            path: '/franchisees/invoiceunit',
            crumbs: ['加盟主档管理', '开票单位列表']
          }, { // 新增单位
            exact: true,
            path: '/franchisees/invoiceunit/addunit',
            crumbs: ['加盟主档管理', '开票单位列表', '新增']
            // crumbs: {
            //   title: '新增开票单位',
            //   parent: ['/franchisees/invoiceunit', '开票单位列表']
            // }
          }, {
            name: '加盟餐厅列表',
            exact: true,
            path: '/franchisees/restaurant',
            crumbs: ['加盟主档管理', '加盟餐厅列表']
          }, {
            exact: true,
            path: '/franchisees/restaurant/join',
            crumbs: ['加盟主档管理', '加盟餐厅列表', '新增']
            // crumbs: { // 面包屑使用 满足场景 暂时使用这种方式
            //   title: '加盟餐厅列表',
            //   parent: ['/franchisees/restaurant', '加盟餐厅列表']
            // }
          }, {
            exact: true,
            path: '/franchisees/restaurant/childStore',
            crumbs: ['加盟主档管理', '加盟餐厅列表', '新增']
            // crumbs: { // 面包屑使用 满足场景 暂时使用这种方式
            //   title: '加盟餐厅列表',
            //   parent: ['/franchisees/restaurant', '加盟餐厅列表']
            // }
          }, {
            name: '危险品BP列表',
            exact: true,
            path: '/franchisees/dangerousBp',
            crumbs: ['加盟主档管理', '危险品BP列表']
          }, {
            name: 'JDE对接数据确认',
            exact: true,
            path: '/franchisees/jdesure',
            crumbs: ['加盟主档管理', '加盟主档JDE对接数据确认']
          }, {
            name: '续展费收取',
            exact: true,
            path: '/franchisees/renewal',
            hasCatch: true,
            crumbs: ['加盟主档管理', '续展加盟餐厅列表']
          }, {
            name: '加盟商画像列表',
            exact: true,
            path: '/franchisees/franportaitlist',
            hasCatch: true,
            crumbs: ['加盟主档管理', '加盟商画像列表']
          }
        ]
      },
      {
        name: '报表',
        children: [
          {
            name: '收集报表',
            exact: true,
            path: '/report/collect',
            crumbs: ['收集报表'],
          }, {
            name: '创建报表模板',
            exact: true,
            path: '/report/create',
            crumbs: ['报表', '创建报表模板'],
          }, {
            name: '使用报表模板',
            exact: true,
            path: '/report/use',
            crumbs: ['报表', '使用报表模板'],
          }, {
            name: '餐厅收费汇总报表',
            exact: true,
            path: '/report/ctsfhz',
            crumbs: ['报表', '餐厅收费汇总报表']
          }
        ]
      },
      {
        name: '运维',
        children: [
          {
            name: '信息通知发布查询',
            exact: true,
            path: '/operation/inforelease',
            hasCatch: true,
            crumbs: ['运维', '信息通知发布查询']
          }, {
            name: '角色管理',
            exact: true,
            path: '/operation/rolemanage',
            crumbs: ['运维', '角色管理']
          }, {
            name: '日志管理',
            exact: true,
            path: '/operation/logManage',
            // hasCatch: true,
            crumbs: ['运维', '日志管理']
          }, {
            name: 'Dashboard',
            exact: true,
            path: '/dashBoard/main',
          },
          {
            name: '用户管理',
            exact: true,
            path: '/operation/user',
            crumbs: ['运维', '用户管理']
          }
        ]
      }, {
        name: '招行对接',
        children: [
          {
            name: '招行对接白名单',
            exact: true,
            path: '/zhdj/whiteList',
            hasCatch: true,
            crumbs: ['招行对接', '招行对接白名单']
          }, {
            name: '招行对接人员维护',
            exact: true,
            path: '/zhdj/roleMaintenance',
            hasCatch: true,
            crumbs: ['招行对接', '招行对接人员维护']
          }, {
            name: '招行账户异常处理',
            exact: true,
            path: '/zhdj/exceptHandle',
            hasCatch: true,
            crumbs: ['招行对接', '招行账户异常处理']
          }
        ]
      }
    ]
  }, {
    name: '数据中心',
    icon: jsptIcon,
    children: [
      {
        name: '数据配置',
        exact: true,
        path: '/basicData/dataconfig',
        crumbs: ['数据中心', '数据配置']
      },
    ]
  }, {
    name: '人事管理',
    icon: jsptIcon,
    children: [
      {
        name: '花名册',
        exact: true,
        path: '/storeManage/roster',
        crumbs: ['门店管理', '花名册']
      },
      {
        name: '人事异动',
        // path: '/personchange',
        children: [
          {
            name: '异动管理',
            exact: true,
            path: '/storeManage/main',
            crumbs: ['人事异动', '异动管理']
          }, {
            name: '异动记录',
            exact: true,
            path: '/storeManage/transactionLog',
            crumbs: ['人事异动', '异动记录']
          },
          // {
          //   exact: true,
          //   path: '/personchange/entry/join',
          //   crumbs: ['人事异动', '入职管理', '新增']
          // },
          // {
          //   exact: true,
          //   path: '/personchange/entry/edit',
          //   crumbs: ['人事异动', '入职管理', '编辑']
          // },
          // {
          //   exact: true,
          //   path: '/personchange/entry/view',
          //   crumbs: ['人事异动', '入职管理', '查看']
          // },
          // {
          //   name: '入职审批',
          //   exact: true,
          //   path: '/personchange/approval',
          //   crumbs: ['人事异动', '入职审批']
          // },
          // {
          //   exact: true,
          //   path: '/personchange/approval/detail',
          //   crumbs: ['人事异动', '入职审批', '审批详情']
          // },
          // {
          //   exact: true,
          //   path: '/personchange/approval/view',
          //   crumbs: ['人事异动', '入职审批', '查看']
          // },
        ]
      }, {
        name: '业务配置',
        // path: '/storeManage',
        children: [
          {
            name: '特殊人员',
            exact: true,
            path: '/storeManage/specialperson',
            crumbs: ['业务配置', '特殊人员']
          },
          {
            name: '员工查询',
            exact: true,
            path: '/storeManage/searchemployee',
            crumbs: ['业务配置', '员工查询']
          },
          {
            name: '预警设置',
            path: '/storeManage/warnsetting',
            crumbs: ['业务配置', '预警设置']
          }
        ]
      }, {
        name: '培训记录',
        path: '/trainingrecord',
        children: [
          {
            name: '业务负责人',
            exact: true,
            path: '/trainingrecord/businessowner',
            crumbs: ['培训记录', '业务负责人']
          },
          {
            name: '其他人员',
            exact: true,
            path: '/trainingrecord/others',
            crumbs: ['培训记录', '其他人员']
          },
        ]
      }, {
        name: '监控报表',
        exact: true,
        path: '/storeManage/monitorform',
        crumbs: ['加盟商招募', '监控报表'],
      }, {
        name: '统计报表',
        children: [
          {
            name: '餐厅架构',
            exact: true,
            path: '/storeManage/restaurant',
            crumbs: ['统计报表', '餐厅架构']
          },
          {
            name: '员工明细',
            exact: true,
            path: '/storeManage/employees',
            crumbs: ['统计报表', '员工明细']
          },
        ]
      },

    ]
  }, {
    name: '加盟商招募',
    icon: jsptIcon,
    children: [
      {
        name: '业务概况',
        exact: true,
        path: '/recruit/business',
        crumbs: ['加盟商招募', '业务概况'],
      }, {
        name: '流程审批',
        exact: true,
        path: '/recruit/approvalall',
        crumbs: ['加盟商招募', '流程审批'],
      }, {
        name: '渠道加盟推送',
        exact: true,
        path: '/recruit/specialprofiles',
        crumbs: ['加盟商招募', '渠道加盟推送'],
      }, {
        name: '特邀加盟推送',
        exact: true,
        path: '/recruit/invite',
        crumbs: ['加盟商招募', '特邀加盟推送'],
      }, {
        name: '报表相关',
        exact: true,
        path: '/recruit/allreport',
        crumbs: ['加盟商招募', '报表相关'],
      }, {
        name: '申请表查询',
        exact: true,
        path: '/recruit/querytable',
        crumbs: ['加盟商招募', '申请表查询'],
      },
      {
        name: 'eFD信息查询',
        exact: true,
        path: '/recruit/efd',
        crumbs: ['加盟商招募', 'eFD信息查询'],
      },
    ]
  }, {
    name: '风险预警',
    icon: jsptIcon,
    children: [
      {
        name: '风险管理',
        exact: true,
        path: '/associate/risk',
        crumbs: ['风险预警', '风险管理']
      },
      {
        name: '监控公司',
        exact: true,
        path: '/associate/monitor',
        crumbs: ['风险预警', '监控公司'],
      },
      {
        name: '风险评估',
        exact: true,
        path: '/associate/onboard',
        crumbs: ['风险预警管理', '风险评估']
      }
    ]
  }, {
    name: 'AI助手',
    icon: jsptIcon,
    children: [
      {
        name: '问答配置',
        exact: true,
        path: '/aiHelper/questions',
        crumbs: ['AI助手', '问答配置']
      },
    ]
  }


];

if (_FRENV_ === 'log') { // 给后端用
  urls = [...log];
} else if (_FRENV_ === 'dev') {
  urls = urls.concat([...example, ...log]);
}

window.console.log(_FRENV_);

export default urls;
