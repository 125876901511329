// redux状态尽量需要时在加，不需要每个页面都加
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { isFSA } from '@/utils';
import reducer from './reducer';
import { Action } from '@/constants'

let middleware: any[] = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger];
}

const store = createStore((state: any, action: Action) => {
  // 检查FSA规范
  if (process.env.NODE_ENV !== 'production') {
    if (!isFSA(action)) {
      console.error(`Against FSA(flux standard action)${action.type}`);
    }
  }
  return reducer(state, action);
},
  {},
  applyMiddleware(...middleware));

export default store;
