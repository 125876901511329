// Todo 加盟商部分，后续合入paysBill
import request from 'utils/request';
import qs from 'qs';
import { AxiosRequest } from '@/constants';

let HOST = '/mininglamp-infra/managementweb';

if (process.env.NODE_ENV !== 'production') {
  HOST = '/mininglamp-infra/managementweb'
}
//  加盟商相关
export function searchFranchisee(data: any) {
  return request({
    url: `${HOST}/franchisee/search`,
    method: 'POST',
    data,
  });
}

export function exportFranchisee(data: any) {
  return request({
    url: `${HOST}/franchisee/export`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}

export function deleteFranchisee(params: any) {
  return request({
    url: `${HOST}/franchisee/delete`,
    method: 'delete',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

export function setPgFranchisee(data: any, editStatusEnum: string = 'edit') {
  return request({
    url: `${HOST}/franchisee/page/jd/${editStatusEnum}`,
    method: 'post',
    data,
  });
}

export function saveRenewBreakDate(data: any) {// 保存加盟商信息截止日期
  return request({
    url: `${HOST}/franchisee/page/jd/saveRenewBreakDate`,
    method: 'post',
    data,
  });
}

export function saveJdJoinAop(data: any) {// 管理员修改获取框架开发协议AOP信息
  return request({
    url: `${HOST}/franchisee/jd/saveJdJoinAop`,
    method: 'post',
    data,
  });
}

export function getJdJoinAopDto(data: any) {// 获取框架开发协议AOP信息
  return request({
    url: `${HOST}/franchisee/jd/getJdJoinAopDto`,
    method: 'post',
    data,
  });
}

export function saveJdBidding(data: any) {// 加盟商竞价保存
  return request({
    url: `${HOST}/franchisee/jd/saveJdBidding`,
    method: 'post',
    data,
  });
}

export function saveJdHonorAndDefault(data: any) {// 保存加盟商荣誉与违约信息
  return request({
    url: `${HOST}/franchisee/saveJdHonorAndDefault`,
    method: 'post',
    data,
  });
}

export function getjdHonorAndDefaultByJdId(params: any) {// 
  return request({
    url: `${HOST}/franchisee/getjdHonorAndDefaultByJdId`,
    method: 'post',
    params,
  });
}

export function franchiseeAdminChange(data: any) {
  return request({
    url: `${HOST}/franchisee/jd/adminChange`,
    method: 'post',
    data,
  });
}


export function franchiseeDelFiles(data: any) {
  return request({
    url: `${HOST}/franchisee/delFiles`,
    method: 'post',
    data,
  });
}

export function getPgFranchisee(params: any) {
  return request({
    url: `${HOST}/franchisee/page`,
    method: 'post',
    params
  });
}
//  加盟餐厅相关
export function searchBusiness(data: any) {
  return request({
    url: `${HOST}/business/search`,
    method: 'POST',
    data,
  });
}

export function exportBusiness(data: any) {
  return request({
    url: `${HOST}/business/export`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}

export function exportJdbidding(data: any) {// 加盟商竞价资格导出
  return request({
    url: `${HOST}/franchisee/export/jdbidding`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}

export function exportTagGroup(data: any) {
  return request({
    url: `${HOST}/business/exportTagGroup`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}

export function importJdbidding(data: any) {// 加盟商竞价资格导入
  return request({
    url: `${HOST}/franchisee/jdbidding/import`,
    method: 'POST',
    data,
  });
}

export function importTagGroup(data: any) {
  return request({
    url: `${HOST}/business/importTagGroup`,
    method: 'POST',
    data
  });
}

export function deleteBusiness(params: any) {
  return request({
    url: `${HOST}/business/delete`,
    method: 'delete',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

export function getBusinessPage(params: any) {
  return request({
    url: `${HOST}/business/page`,
    method: 'POST',
    params
  });
}

export function businessAdminSave(data: any) {
  return request({
    url: `${HOST}/business/page/jdBusiness/adminSaveBusinesses`,
    method: 'POST',
    data
  });
}

export function setBusinessPage(data: any, editStatusEnum: string) {
  return request({
    url: `${HOST}/business/page/jdBusiness/${editStatusEnum}`,
    method: 'POST',
    data,
  });
}

export function submitWithoutJdeSync(data: any) {
  return request({
    url: `${HOST}/business/page/jdBusiness/submitWithoutJdeSync`,
    method: 'POST',
    data,
  });
}


export function getNameOrBuCode(params: any) {
  return request({
    url: `${HOST}/yum_whitelist/search/nameOrBuCode`,
    method: 'get',
    params,
    // noShowLoading: true
  } as AxiosRequest);
}

export function searchMarket(params: any) {
  return request({
    url: `${HOST}/search`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

export function getJdeCodeOrHypeCode(params: any) {
  return request({
    url: `${HOST}/business/search/jdeCodeOrHypeCode`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

export function getSearchByCompany(data: any) {
  return request({
    url: `${HOST}/jd-company-bu/search/byCompany`,
    method: 'post',
    data,
    noShowLoading: true,
    noInfos: true
  } as AxiosRequest);
}

export function getSelectLists(params: any) {
  return request({
    url: `${HOST}/jdeUdcCode/list`,
    method: 'get',
    params,
    // noShowLoading: true
  } as AxiosRequest);
}

export function getAssetTypeCode(params: any) {
  return request({
    url: `${HOST}/search/assetTypeCode`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}


export function getBusinessDistrict(params: any) {
  return request({
    url: `${HOST}/search/businessDistrict`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

export function getProvienceCity(params: any) {
  return request({
    url: `${HOST}/search/provienceCity`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

export function getYumCenter(params: any) {
  return request({
    url: `${HOST}/yum_distribution_center`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

export function getCompanyRelation(params: any) {
  return request({
    url: `${HOST}/jd_company_bp_relation`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

// JDE 确认列表
export function getJdDataList(params: any) {
  return request({
    url: `${HOST}/jdDataValidation/list`,
    method: 'get',
    params,
    paramsSerializer: (paras: any) => {
      return qs.stringify(paras, { arrayFormat: 'repeat' })
    }
  });
}

// JDE 确认列表导出
export function getJdDataExport(params: any, fileName?: string) {
  return request({
    url: `${HOST}/jdDataValidation/export`,
    method: 'get',
    params,
    paramsSerializer: (paras: any) => {
      return qs.stringify(paras, { arrayFormat: 'repeat' })
    },
    fileName,
    responseType: 'arraybuffer',
    noShowLoading: false
  } as AxiosRequest);
}

// JDE 退回原因
export function getJdDataQueryBackReason(params: any) {
  return request({
    url: `${HOST}/jdDataValidation/queryBackReason`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

// JDE 退回
export function getJdDataQueryBack(data: any) {
  return request({
    url: `${HOST}/jdDataValidation/back`,
    method: 'post',
    data,
  });
}

// JDE 重新提交
export function getJdDataQueryRepush(data: any) {
  return request({
    url: `${HOST}/jdDataValidation/repush`,
    method: 'post',
    data,
  });
}

// JDE 一键提交
export function getJdDataQueryConfirm(data: any) {
  return request({
    url: `${HOST}/jdDataValidation/confirm`,
    method: 'post',
    data,
  });
}
export function updatedJdDataFields(params: any) {// 根据类型查询修改的字段的集合
  return request({
    url: `${HOST}/jdDataValidation/updated-fields`,
    method: 'get',
    params,
    noShowLoading: true,
    paramsSerializer: (paras: any) => {
      return qs.stringify(paras, { arrayFormat: 'repeat' })
    }
  } as AxiosRequest);
}

export function getJdConfirmAction(data: any) {// 当前用户对该条通知的操作结果
  return request({
    url: `${HOST}/jd/letterOfConfirmation​/action`,
    method: 'post',
    data,
  });
}

export function getJdConfirmNeedShow(params: any) {// 根据发布渠道获取不同场景下的用户通知列表
  return request({
    url: `${HOST}/jd/letterOfConfirmation​/needShow`,
    method: 'get',
    params,
  });
}

export function getJdConfirmDetail(params: any) {// 获取详情
  return request({
    url: `${HOST}/jd/letterOfConfirmation`,
    method: 'get',
    params,
  });
}

export function updateJdConfirmDetail(data: any) {// 更新
  return request({
    url: `${HOST}/jd/letterOfConfirmation`,
    method: 'put',
    data,
  });
}

export function addJdConfirmDetail(data: any) {// 新增
  return request({
    url: `${HOST}/jd/letterOfConfirmation`,
    method: 'post',
    data,
  });
}

export function delJdConfirmDetail(params: any) {// 删除
  return request({
    url: `${HOST}/jd/letterOfConfirmation`,
    method: 'delete',
    params,
  });
}

export function getJdConfirmDetailList(params: any) {// 查询列表
  return request({
    url: `${HOST}/jd/letterOfConfirmation/list`,
    method: 'get',
    params,
  });
}

// 根据发布渠道获取不同场景下当前用户需要展示的通知列表
export function getJdLetterOfConfirmationNeedShow(data: any) {
  return request({
    url: `${HOST}/jd/letterOfConfirmation/needShow`,
    params: data,
  });
}

// 当前用户对该条通知的操作结果，根据显示类型showType进行区分，通知函只有同意和不同意，没有已读
export function setJdLetterOfConfirmationAction(data: any) {
  return request({
    url: `${HOST}/jd/letterOfConfirmation/action`,
    data,
    method: 'post'
  });
}

export function getDashboardProvince() {// 查询省份分组之后的数据
  return request({
    url: `${HOST}/dashboard/search/province`,
    method: 'get',
    params: {},
  });
}

export function getDashboardBrand() {// 获取省份数据
  return request({
    url: `${HOST}/dashboard/search/brand`,
    method: 'get',
    params: {},
  });
}

export function getDashboardProvinces() {// 获取省份数据
  return request({
    url: `${HOST}/dashboard/provinces`,
    method: 'get',
    params: {},
  });
}

export function getDashboardLines() {// 折线图
  return request({
    url: `${HOST}/dashboard/search/lines`,
    method: 'get',
    params: {},
  });
}

export function getDashboardAop() {// aop查询
  return request({
    url: `${HOST}/dashboard/search/aop`,
    method: 'get',
    params: {},
  });
}
export function getDashboardMonthStore() {// aop查询
  return request({
    url: `${HOST}/dashboard/search/month/store`,
    method: 'get',
    params: {},
  });
}

// 经营管理架构获取用户信息
export function getDirectlyMng(params: any) {
  return request({
    url: `${HOST}/search/directlyMng`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}
export function selectAllJdName() {// 返回所有已提交的加盟商名称jdCalledBy
  return request({
    url: `${HOST}/franchisee/selectAllJdName`,
    method: 'get',
    params: {},
    noShowLoading: false
  } as AxiosRequest);
}
export function selectAllCompanyName() {// 返回所有已提交的加盟商名称jdCalledBy
  return request({
    url: `${HOST}/jd-company/selectAllCompanyName`,
    method: 'post',
    data: {},
    noShowLoading: false
  } as AxiosRequest);
}
export function searchBuNameList() {// 查询开票单位名称
  return request({
    url: `${HOST}/jd-company-bu/searchBuNameList`,
    method: 'post',
    data: {},
    noShowLoading: false
  } as AxiosRequest);
}
export function getAllBusinessName() {// 查询开票单位名称
  return request({
    url: `${HOST}/business/getAllBusinessName`,
    method: 'get',
    params: {},
    noShowLoading: false
  } as AxiosRequest);
}
export function getAllBusinessNo() {// 查询开票单位名称
  return request({
    url: `${HOST}/business/getAllBusinessNo`,
    method: 'get',
    params: {},
    noShowLoading: false
  } as AxiosRequest);
}
export function searchJdbusiness(params: any) {// 根据餐厅找到对应公司下开票单位
  return request({
    url: `${HOST}/jd-company-bu/search/jd-business`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}
export function codesOrName(data: any) {
  return request({
    url: `${HOST}/business/jd_business/codesOrName`,
    method: 'POST',
    data, noShowLoading: true
  } as AxiosRequest);
}
export function assetPageSearch(data: any) {
  return request({
    url: `${HOST}/page/search`,
    method: 'POST',
    data,
    noShowLoading: true
  } as AxiosRequest);
}
export function deleteBrandRelationshipCode(data: any) {// 删除brand
  return request({
    url: `${HOST}/deleteBrandRelationshipCode`,
    method: 'POST',
    data,
  } as AxiosRequest);
}
export function saveBrandRelationshipCode(data: any) {// 保存brand
  return request({
    url: `${HOST}/saveBrandRelationshipCode`,
    method: 'POST',
    data,
  } as AxiosRequest);
}
export function exportBrandRelationshipCode(data: any) {// 导出brand
  return request({
    url: `${HOST}/exportBrandRelationshipCode`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  } as AxiosRequest);
}
export function exportYumDistributionCenter(data: any) {// 导出餐厅所属百胜配销中心
  return request({
    url: `${HOST}/exportYumDistributionCenter`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  } as AxiosRequest);
}
export function queryYumDistributionCenter(data: any) {// 导出餐厅所属百胜配销中心
  return request({
    url: `${HOST}/queryYumDistributionCenter`,
    method: 'POST',
    data,
  } as AxiosRequest);
}
export function saveYumDistributionCenter(data: any) {// 导出餐厅所属百胜配销中心
  return request({
    url: `${HOST}/saveYumDistributionCenter`,
    method: 'POST',
    data,
  } as AxiosRequest);
}
export function deleteYumDistributionCenter(data: any) {// 导出餐厅所属百胜配销中心
  return request({
    url: `${HOST}/deleteYumDistributionCenter`,
    method: 'POST',
    data,
  } as AxiosRequest);
}
// 餐厅立项相关接口
export function businessProjectSearch(data: any) {// 分页查询
  return request({
    url: `${HOST}/businessProject/search`,
    method: 'POST',
    data,
  } as AxiosRequest);
}
export function businessProjectSaveBusinessesProject(data: any) {// 保存加盟餐厅立项数据
  return request({
    url: `${HOST}/businessProject/saveBusinessesProject`,
    method: 'POST',
    data,
  } as AxiosRequest);
}
export function businessProjectSearchProjectById(params: any) {// 查询立项详情
  return request({
    url: `${HOST}/businessProject/searchProjectById`,
    method: 'get',
    params,
  } as AxiosRequest);
}
export function businessProjectCheckProject(params: any) {// 判断立项申请是否续改关
  return request({
    url: `${HOST}/businessProject/checkProject`,
    method: 'GET',
    params,
    noShowLoading: true
  } as AxiosRequest);
}
export function businessProjectExport(data: any) {
  return request({
    url: `${HOST}/businessProject/export`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
    noShowLoading: false
  } as AxiosRequest);
}
export function businessAopSearch(data: any) {// 分页查询
  return request({
    url: `${HOST}/businessAop/search`,
    method: 'post',
    data,
  } as AxiosRequest);
}
export function businessAopSearchAopById(params: any) {// 查询餐厅AOP详情
  return request({
    url: `${HOST}/businessAop/searchAopById`,
    method: 'get',
    params,
  } as AxiosRequest);
}
export function businessAopSaveBusinessesAop(data: any) {// 保存加盟餐厅AOP数据
  return request({
    url: `${HOST}/businessAop/saveBusinessesAop`,
    method: 'post',
    data,
  } as AxiosRequest);
}
export function businessAopBatchQuery(data: any) {// 批量确认
  return request({
    url: `${HOST}/businessAop/batch/query`,
    method: 'post',
    data,
  } as AxiosRequest);
}
export function businessAopExport(data: any) {// 加盟商资产AOP导出
  return request({
    url: `${HOST}/businessAop/export`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
    noShowLoading: false
  } as AxiosRequest);
}
export function businessAopPushAopToDmis() {// 推送数据到dmis
  return request({
    url: `${HOST}/businessAop/pushAopToDmis`,
    method: 'get',
    params: {},
  } as AxiosRequest);
}

export function businessAopAopFinal(data: any) {// 分页查询DMIS推送的最终AOP计划
  return request({
    url: `${HOST}/businessAop/searchAopFinal`,
    method: 'post',
    data,
  } as AxiosRequest);
}

export function businessAopAopFinalExport(data: any) {// DMIS推送的最终AOP计划导出
  return request({
    url: `${HOST}/businessAop/exportAopFinal`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
    noShowLoading: false
  } as AxiosRequest);
}
export function businessProjectDeleteDraftSave(data: any) {// 删除草稿
  return request({
    url: `${HOST}/businessProject/deleteDraftSave`,
    method: 'POST',
    data,
  } as AxiosRequest);
}
export function businessProjectDraftSaveBusinessesProject(data: any) {// 保存草稿
  return request({
    url: `${HOST}/businessProject/draftSaveBusinessesProject`,
    method: 'POST',
    data,
  } as AxiosRequest);
}
export function getAdsMapReport(data: any) {
  return request({
    url: `${HOST}/business/getAdsMapReport`,
    method: 'POST',
    data,
  });
}
export function getAdsPipelineMapReport(data: any) {
  return request({
    url: `${HOST}/business/getAdsPipelineMapReport`,
    method: 'POST',
    data,
  });
}
export function getAdsCategory() {
  return request({
    url: `${HOST}/business/getAdsCategory`,
    method: 'POST',
    data: {},
  });
}
export function getAdsSubCategory(data: any) {
  return request({
    url: `${HOST}/business/getAdsSubCategory`,
    method: 'POST',
    params: data,
  });
}

export function exportjdbiddingBd(data: any) {// 加盟商信用评级导出
  return request({
    url: `${HOST}/franchisee/export/jdbiddingBd`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}

export function importBdJdbidding(data: any) {// 加盟商信用评级导入
  return request({
    url: `${HOST}/franchisee/jdbidding/importBd`,
    method: 'POST',
    data,
  });
}

export function exportHonorAndDefault(params: any) {
  return request({
    url: `${HOST}/franchisee/exportHonorAndDefault`,
    method: 'POST',
    params,
    responseType: 'arraybuffer',
  });
}

export function saveJdBiddingBd(data: any) {
  return request({
    url: `${HOST}/franchisee/jd/saveJdBiddingBd`,
    method: 'post',
    data,
  });
}
export function getChannelManager() {
  return request({
    url: `${HOST}/business/getChannelManager`,
    method: 'POST',
  });
}