// 收费项配置模块
import request from 'utils/request';
// import qs from 'qs';
import { AxiosRequest } from '@/constants';

let HOST = '/mininglamp-infra/jd';

if (process.env.NODE_ENV !== 'production') {
  HOST = '/mininglamp-infra/jd'
}

export function addDaySales(data: any) {
  return request({
    url: `${HOST}/daySales/addDaySales`,
    method: 'POST',
    data,
    noShowLoading: true
  } as AxiosRequest);
}

export function queryDaySalesList(data: any) {
  return request({
    url: `${HOST}/daySales/queryDaySalesList`,
    method: 'POST',
    data,
    noShowLoading: true
  } as AxiosRequest);
}

export function importData(data: any) {
  return request({
    url: `${HOST}/daySales/importData`,
    method: 'POST',
    data
  });
}

export function addIncomeApply(data: any) {
  return request({
    url: `${HOST}/incomeApply/addIncomeApply`,
    method: 'POST',
    data,
    noInfos: true
  } as AxiosRequest);
}

export function queryIncomeApplyHisDetail(params: any) {
  return request({
    url: `${HOST}/incomeApply/queryIncomeApplyHisDetail`,
    method: 'GET',
    params
  });
}

export function queryIncomeApplyHis(data: any) {
  return request({
    url: `${HOST}/incomeApply/queryIncomeApplyHis`,
    method: 'POST',
    data
  });
}

export function queryAppendSalesList(data: any) {
  return request({
    url: `${HOST}/appendSales/queryAppendSalesList`,
    method: 'POST',
    data
  });
}

export function queryAppendSalesListCopy(data: any) {
  return request({
    url: `${HOST}/appendSales/queryAppendSalesList`,
    method: 'POST',
    data,
    noInfos: true,
    noShowLoading: true
  } as AxiosRequest);
}

export function queryAppendDaySalesList(data: any) {
  return request({
    url: `${HOST}/appendSales/queryAppendDaySalesList`,
    method: 'POST',
    data
  });
}

export function commitAppendSales(data: any) {
  return request({
    url: `${HOST}/appendSales/commit`,
    method: 'POST',
    data
  });
}

export function exportAppendSales(data: any) {
  return request({
    url: `${HOST}/appendSales/export`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}

export function exportDaySales(data: any) {
  return request({
    url: `${HOST}/daySales/exportDaySales`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}

export function exportApplyHis(data: any) {
  return request({
    url: `${HOST}/incomeApply/exportApplyHis`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}

export function importAppendSales(data: any) {
  return request({
    url: `${HOST}/appendSales/import`,
    method: 'POST',
    data,
  });
}

export function incomeApplyCacheData(data: any) {
  return request({
    url: `${HOST}/incomeApply/cacheData`,
    method: 'POST',
    data,
    noInfos: true
  } as AxiosRequest);
}

export function getIncomeApplyCacheData(yearMonth: any) {
  return request({
    url: `${HOST}/incomeApply/cacheData/${yearMonth}`,
    method: 'get',
    params: {}
  });
}

export function queryMonthSales(data: any) {// 收入申报查询月收入，taxRate ， 分割表数据
  return request({
    url: `${HOST}/monthSales/queryMonthSales`,
    method: 'post',
    data
  });
}

export function billReportSummary() {
  return request({
    url: `${HOST}/bill/report/summary`,
    method: 'get',
    params: {},
    noShowLoading: true
  } as AxiosRequest);
}

export function billReportSearch(params: any) {
  return request({
    url: `${HOST}/bill/report/search`,
    method: 'get',
    params
  });
}

export function billReportExport(data: any) {
  return request({
    url: `${HOST}/bill/report/export`,
    method: 'post',
    data,
    // paramsSerializer: (paras: any) => {
    //   return qs.stringify(paras, { arrayFormat: 'repeat' })
    // },
    responseType: 'arraybuffer',
  });
}

export function billReportExportall(data: any) {
  return request({
    url: `${HOST}/bill/report/exportall`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
  });
}

export function billReportExportNegative(data: any) {
  return request({
    url: `${HOST}/bill/report/export-negative`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
  });
}

export function billReportExportAllOnMiniFile() {
  return request({
    url: `${HOST}/bill/report/exportAllOnMiniFile`,
    method: 'post',
    responseType: 'arraybuffer',
  });
}

export function billReportDetail(params: any) {
  return request({
    url: `${HOST}/bill/report/detail`,
    method: 'get',
    params
  });
}

export function billReportRestaurantDetail(params: any) {
  return request({
    url: `${HOST}/bill/report/restaurant/detail`,
    method: 'get',
    params
  });
}

export function billReportBankInstructDetail(params: any) {
  return request({
    url: `${HOST}/bill/report/bank-instruct/detail`,
    method: 'get',
    params
  });
}

export function isTimeWindow() {
  return request({
    url: `${HOST}/appendSales/isTimeWindow`,
    method: 'get',
    params: {},
  });
}

export function getTaxRateDeploy() {
  return request({
    url: `${HOST}/incomeApply/getTaxRateDeploy`,
    method: 'get',
    params: {},
  });
}
export function insertAppendDaySales() {// 手动触发日营业额补录-用于日销售收入完整性检查报表
  return request({
    url: `${HOST}/appendSales/insertAppendDaySales`,
    method: 'post',
    data: {},
  });
}
export function sendMailAppendDaySales() {// 手动触发日营业额补录发送邮件-用于日销售收入完整性检查报表
  return request({
    url: `${HOST}/appendSales/sendMailAppendDaySales`,
    method: 'post',
    data: {},
  });
}
export function batchUpdate(data: any) {// 批量更新
  return request({
    url: `${HOST}/appendSales/batchUpdate`,
    method: 'post',
    data,
  });
}
// 查询当前用户当月收入申报是否有数据,如果有就返回为true表示已提交
export function ifJdIncomeApplySubmit() {
  return request({
    url: `${HOST}/incomeApply/ifJdIncomeApplySubmit`,
    method: 'POST',
    data: {}
  });
}
// 收入申报预览数据
export function queryJdIncomeApplyPreview(data: any) {
  return request({
    url: `${HOST}/incomeApply/queryJdIncomeApplyPreview`,
    method: 'POST',
    data
  });
}
// 收入申报预览数据导出
export function exportJdIncomeApplyPreview(data: any) {
  return request({
    url: `${HOST}/incomeApply/exportJdIncomeApplyPreview`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}
export function getJdIncomeApplyNowExport() {
  return request({
    url: `${HOST}/incomeApply/getJdIncomeApplyNowExport`,
    method: 'POST',
    data: {},
    responseType: 'arraybuffer',
  });
}

export function exportJdIncomeApplyDetail(data: any) {
  return request({
    url: `${HOST}/incomeApply/exportJdIncomeApplyDetail`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}