import React from 'react';
import { Pagination } from 'antd';

interface IProps {
  total: number,
  current?: number,
  onChange: (page: any, pageSize: any) => void,
  showsizechanger?: boolean,
  defaultPageSize?: number,
  pageSizeOptions?: string[],
  showQuickJumper?: boolean
}

function EncPagination(props: IProps) {
  const { total, current, onChange, showsizechanger = true, showQuickJumper = true,
    pageSizeOptions = ['5', '20', '50', '100', '200', '500'], defaultPageSize = 20 } = props;
  return (
    <div className="m-t-15 enc-pagina">
      <Pagination
        current={current}
        className="align-right"
        total={total}
        showSizeChanger={showsizechanger}
        showQuickJumper={showQuickJumper}
        onChange={onChange}
        defaultPageSize={defaultPageSize}
        pageSizeOptions={pageSizeOptions}
        showTotal={(totals) => (
          <span className="text-bf">共 {totals} 条</span>
        )}
      />
    </div >
  );
}

export default EncPagination;
