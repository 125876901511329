import React, {
  Component,
  // PureComponent
} from 'react';
import { RouteComponentProps, withRouter } from "react-router";
import {
  Menu, Select, Space,
  Breadcrumb, Result
} from 'antd';
import { Link } from 'react-router-dom';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import actions from '@/store/actions';
import classnames from 'classnames';
import urls from '@/config/url';
import Logo from '@/images/logo.png';
import style from './style.less';
import Watermark from '@uiw/react-watermark';
if (_FRENV_ === 'dev' || _FRENV_ === 'online') {
  document.domain = 'hwwt2.com'; // iframe内嵌
}

const { SubMenu } = Menu;

interface LayoutComponentProps extends RouteComponentProps<any> {
  children: any;
}

const noMenu = ['/login', '/iframe', '/aiAssistantManage'];


const mapStateToProps = (state: any) => ({
  ...state.modules.example.redux,
  post: state.common.post,
  authority: state.common.authority,
  trader: state.common.trader,
  roles: state.common.roles,
  username: state.common.username,
  psid: state.common.psid,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({
    setCrumbs: actions.setCrumbs,
    setAllDict: actions.setAllDict,
    setPost: actions.setPost,
    setTrader: actions.setTrader,
    setAuthority: actions.setAuthority,
    setUserInfos: actions.setUserInfos,
    setReceiveUnit: actions.setReceiveUnit,
    setMasterMarket: actions.setMasterMarket,
  }, dispatch),
});
window.crumbs = [];
let unlisten: any
let init = false
class Layout extends Component<LayoutComponentProps> {
  public state = {
    collapsed: false,
  };
  public crumbsObj = {}

  public componentDidMount() {
    const {
      // setCrumbs,
      setAllDict, setUserInfos, setReceiveUnit, setMasterMarket, location, history }: any = this.props;
    // setCrumbs({ crumbs: this.crumbsObj });
    window.addEventListener('DOMNodeInserted', this.pathChange);
    // top.addEventListener('scroll', this.pathChange);
    // button-fixed
    if (![...noMenu, '/recruit/add'].some((item: any) => location.pathname.indexOf(item) !== -1)) {
      init = true
      setAllDict();
      setUserInfos();
      setReceiveUnit();
      setMasterMarket();
    } else {
      if (init || !noMenu.some((item: any) => location.pathname.indexOf(item) !== -1)) {
        setAllDict();
        setMasterMarket();
        unlisten?.();
      } else {
        unlisten = history.listen((location: any) => {
          // 处理路由变化的逻辑
          if (init || !noMenu.some((item: any) => location.pathname.indexOf(item) !== -1)) {
            setAllDict();
            setMasterMarket();
            unlisten?.();
          } else {
            init = true
          }
        });
      }
    }

  }

  public componentWillUnmount() {
    window.removeEventListener('DOMNodeInserted', this.pathChange);
    unlisten?.();
    // top.removeEventListener('onscroll', this.pathChange);
  }

  public shouldComponentUpdate() {
    let pn = window.location.hash.slice(1).split('?')[0];
    const pns = pn.split('/');
    if (pns.length > 4) {
      pns.pop();
      pn = pns.join('/') + '/:type'
    }
    window.crumbs = this.crumbsObj[pn]?.crumbs || []; // 给方正用
    if (top === window) {
      top.postMessage(window.crumbs, '*')
    }
    if (top !== window) {
      // tslint:disable-next-line:max-line-length
      // top.document.querySelectorAll("iframe")[0].height = (window.document.querySelector('.franchisee-content')?.scrollHeight || 0) + 'px'
      // tslint:disable-next-line:max-line-length
      top.document.querySelectorAll("iframe")[0].height = String((top.document.getElementById("root")?.offsetHeight || 623) - 102) + 'px'
      top.document.querySelectorAll("iframe")[0].style.minHeight = '100%'
    }
    return true;
  }

  public pathChange = () => {
    window.requestAnimationFrame(() => {
      const bf: any = document.querySelector(".button-fixed")
      // const am: any = document.querySelector(".ant-message")
      // window.console.log(window.document.querySelector('.franchisee-content')?.scrollHeight)
      if (top !== window) {
        // tslint:disable-next-line:max-line-length
        // top.document.querySelectorAll("iframe")[0].height = (window.document.querySelector('.franchisee-content')?.scrollHeight || 0) + 'px'
        // tslint:disable-next-line:max-line-length
        top.document.querySelectorAll("iframe")[0].height = String((top.document.getElementById("root")?.offsetHeight || 623) - 102) + 'px'
        top.document.querySelectorAll("iframe")[0].style.minHeight = '100%'
        // if (bf) {
        // tslint:disable-next-line:max-line-length
        // bf.style.top = `${(top.document.documentElement.scrollTop || 0) + top.document.documentElement.clientHeight - 170}px`
        // }
        // if (am) {
        //   am.style.top = `${(top.document.documentElement.scrollTop || 0) + 100}px`
        // }
      }
      // else {
      // tslint:disable-next-line: max-line-length
      //   if (bf) { bf.style.top = (document.documentElement.scrollTop || 0) + document.documentElement.clientHeight - 142 + 'px' }
      // }
      if (bf) {
        bf.style.display = 'inline-flex'
      }
    });
    // this.timer = setTimeout(() => {

    // }, 1000/60)

  }

  public toggleCollapsed = () => {
    const { collapsed } = this.state;
    this.setState({
      collapsed: !collapsed,
    });
  };

  // public crumbs = (data: any) => {
  //   const { history }: any = this.props;
  //   return () => {
  //     history.push(data.path);
  //   }
  // }

  public setMemu = (menu: any[]) => {
    return menu.map((item, idx) => {
      // tslint:disable-next-line:no-null-keyword
      const Icon = item.icon || null;
      if (item.path && !this.crumbsObj[item.path]) {
        this.crumbsObj[item.path] = item
      }
      if (!item.name) {
        // tslint:disable-next-line:no-null-keyword
        return null;
      }
      if (item.children) {
        return (
          // tslint:disable-next-line:no-null-keyword
          <SubMenu key={item.path || item.name} icon={Icon ? <Icon /> : null} title={item.name}>
            {this.setMemu(item.children)}
          </SubMenu>
        );
      } else {
        return (
          <Menu.Item key={item.path}>
            <Link to={item.path}>{item.name}</Link>
          </Menu.Item>);
      }
    })
  }


  public render() {
    const { children, location, setPost, post, setAuthority, authority, roles, username, trader, setTrader, psid }: any = this.props;
    const result = window.location.href.indexOf('b.hwwt2.com') === -1
    const result1 = window.location.href.indexOf('dashBoard/main') === -1
    if (_FRENV_ === 'online' && (username === '904' || (username && !Object.keys(roles).length))) {
      return <Result
        // icon={<SmileOutlined />}
        title="获取用户信息失败，请尝试重新登录"
      // extra={<Button type="primary">Next</Button>}
      />
    }
    return (
      <div className={classnames(style.layout, 'flex')}>
        {(result && (!noMenu.some((item: any) => location.pathname.indexOf(item) !== -1)) && _FRENV_ !== 'online') && <div className="franchisee-top flex jc-between al-center">
          <div>
            <img src={Logo} />
            加盟新基建
          </div>
          <div className="franchisee-breadcrumb">
            <Breadcrumb>
              {window.crumbs.map((crm: any, index: number) => (<Breadcrumb.Item key={index}>{crm}</Breadcrumb.Item>))}
            </Breadcrumb>
          </div>
          {
            (_FRENV_ !== 'log' && _FRENV_ !== 'online') && (
              <Space>
                <Select style={{ width: 160 }} onChange={setTrader} value={trader}>
                  <Select.Option value={"0"}>加盟商</Select.Option>
                  <Select.Option value={"1"}>管理经理</Select.Option>
                  <Select.Option value={"2"}>上级领导</Select.Option>
                </Select>
                <Select style={{ width: 160 }} onChange={setTrader} value={trader}>
                  <Select.Option value={"1"}>申诉用加盟商</Select.Option>
                  <Select.Option value={"2"}>申诉用支持经理</Select.Option>
                </Select>
                <Select style={{ width: 160 }} onChange={setPost} value={post}>
                  <Select.Option value={"1"}>维护岗</Select.Option>
                  <Select.Option value={"2"}>确认岗</Select.Option>
                </Select>
                <Select style={{ width: 160 }} onChange={setAuthority} value={authority}>
                  <Select.Option value={"1"}>Onboard专员</Select.Option>
                  <Select.Option value={"2"}>支持经理</Select.Option>
                  <Select.Option value={"3"}>加盟部收费项清单确认岗</Select.Option>
                </Select>
              </Space>
            )
          }
        </div>}
        <div className="franchisee-bottom flex">
          {(result && (!noMenu.some((item: any) => location.pathname.indexOf(item) !== -1)) && _FRENV_ !== 'online') && <div className={classnames('franchisee-left', {
            'franchisee-collapsed': this.state.collapsed,
          })}
          >
            <Menu
              defaultSelectedKeys={[location.pathname || '/']}
              defaultOpenKeys={[`/${location.pathname.split('/')[1]}` || '/']}
              mode="inline"
              inlineCollapsed={this.state.collapsed}
            >
              {this.setMemu(urls)}
            </Menu>
            {/* <Button
              type="primary"
              className={classnames({
                'btn-collapsed': !this.state.collapsed,
              })}
              onClick={this.toggleCollapsed}
              style={{ marginBottom: 16 }}
            >
              {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
            </Button> */}
          </div>}

          <div className="franchisee-right">
            <div className={`franchisee-content${_FRENV_ === 'prod' && result1 ? ' p-sm' : ''}`}>
              <Watermark
                content={[`${username}`, `${psid}`]}
                rotate={-45}
                gapY={80}
                gapX={120}
                fontSize={16}
                fontColor="rgba(0, 0, 0, 0.1)"
                style={{ background: '#fff', width: '100%' }}
              >
                {children}
              </Watermark>

            </div>
          </div>
        </div>

      </div >
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
