// 组件相关逻辑尽量放在组件里面
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom'
import EncTable, { ColumnsType, TableProps } from '@/components/enctable';
import EncPagination from '@/components/encpagination';
// import {
//   Table, Typography
// } from 'antd';
import actions from '@/store/actions';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSelfId } from "@/utils";
const mapDispatchToProps = (dispatch: Dispatch) => ({
  ...bindActionCreators({
    setPagination: actions.setPagination
  }, dispatch),
});

// const { Text } = Typography;
export type { ColumnsType }
export interface IProps extends TableProps<object> {
  request?: any,
  requestParams?: any,
  rowKey: any,
  columns: ColumnsType<object>,
  // dataSource?: any[],
  loading?: boolean | object,
  // onChange?: (pagination: object) => any,
  rowSelection?: object,
  // summaryFields?: string[]// 需要对table列表字段进行统计合并，目前只针对数字
  // total?: number
  // summary?: (data: any) => void
  setPagination?: any// store 传入，无需关注
  needOnly?: boolean,
  callBackCount?: (val: number) => void,
  pagOnChange?: () => void,
  callPageNo?: (pageNo: number) => void,
  callhasDatas?: (data: any[]) => void,
  hasPagination?: boolean,// 是否包含分页筛选
  paramNotEmpty?: boolean,// 没传参数不进行请求
  recursive?: string,
  pageSizeOptions?: string[],
  handleLists?: (res: any) => { dataSource: any, total: number },
  requestback?: () => void,
  needload?: boolean,// 需要加载
  showquickjump?: boolean,
  currentname?: string
}

function Paging({
  columns, request, requestParams, setPagination,
  callBackCount,
  needOnly = false,
  pagOnChange,
  callPageNo,
  callhasDatas,
  hasPagination = true,
  paramNotEmpty = false, needload = true, showquickjump,
  recursive, pageSizeOptions, handleLists, requestback, currentname = 'pageNum',
  ...others
}: IProps) {
  const { search } = useLocation()
  const [data, setData] = useState({
    dataSource: [],
    total: 0,
  });
  const [lastParams, setlastParams] = useState<any>(requestParams);
  const [intParams, setIntParams] = useState({
    currentPage: 1,
    pageSize: 20,
  });

  useEffect(() => {
    setPagination(intParams)
  }, [intParams, setPagination]);

  useEffect(() => {
    setData({
      dataSource: [],
      total: 0,
    })
  }, [search])

  // tslint:disable-next-line:no-shadowed-variable
  const getlists = useCallback((intParams, requestParams, lastParams, tableParams = {}) => {
    if (!needload) { return }
    if (requestParams && (!lastParams ||
      lastParams && JSON.stringify(Object.values(requestParams)) !== JSON.stringify(Object.values(lastParams)))) {
      setlastParams(requestParams)
      setIntParams(bf => ({
        pageSize: bf.pageSize,
        currentPage: 1
      }))
      return
    }
    if (paramNotEmpty && !Object.keys(requestParams).length) {
      return
    }
    if (paramNotEmpty && requestParams.templateId && Object.keys(requestParams).length === 1) {
      return
    }
    const params = {
      ...requestParams,
      ...tableParams,
      // pageNum: intParams.currentPage,
      // pageSize: intParams.pageSize,
    };
    if (hasPagination) {
      params[currentname] = intParams.currentPage
      params.pageSize = intParams.pageSize
    }
    const func = (datas: any[], recursive: string) => {
      for (let das of datas) {
        if (needOnly) {
          das.rowId = getSelfId()
        }
        if (das[`${recursive}`]) {
          func(das[`${recursive}`], recursive)
        }
      }
      return datas
    }
    request(params).then((res: any) => {
      if (!res.code) {
        callBackCount?.(res.total || res.totalElements)
        if (handleLists) {
          const { dataSource, total } = handleLists(res)
          return setData({
            dataSource,
            total,
          });
        } else if (res.content) {
          const dataSource = res.content.map((p: any, idx: number) => ({
            ...p,
            rowId: needOnly ? getSelfId() : "",
            No: ((intParams.currentPage - 1) * intParams.pageSize + idx) * 1 + 1
          })) || []
          callhasDatas?.(dataSource)
          return setData({
            dataSource,
            total: res.totalElements,
          });
        } else if (res.list) {
          let dataSource = []
          if (recursive) {
            dataSource = func(res.list, recursive)
          } else {
            dataSource = res.list.map((p: any) => ({
              ...p,
              ...needOnly && { rowId: getSelfId() }
            }))
          }
          callhasDatas?.(dataSource)
          return setData({
            dataSource,
            total: res.total,
          });
        } else {
          let dataSource: any = []
          if (recursive) {
            dataSource = func(res, recursive)
          } else if (Array.isArray(res)) {
            dataSource = res.map((p: any) => ({
              ...p,
              ...needOnly && { rowId: getSelfId() }
            }))
          }
          callhasDatas?.(dataSource)
          return setData({
            dataSource,
            total: res.total,
          });
        }
      }
    }).finally(() => {
      requestback?.()
    });
  }, [recursive, handleLists, needload, paramNotEmpty, hasPagination, currentname])

  useEffect(() => {
    getlists(intParams, requestParams, lastParams);
    pagOnChange?.()
    callPageNo?.(intParams.currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getlists, intParams, requestParams, lastParams]);

  // 获取数据
  // const getlist = () => {
  //   const params = {
  //     ...requestParams,
  //     pageNum: intParams.currentPage,
  //     pageSize: intParams.pageSize,
  //   };
  //   request(params).then((res: any) => {
  //     return setData({
  //       dataSource: res.list || [],
  //       total: res.total,
  //     });
  //   });
  // };

  // table
  const tableOnChange = ( // action: paginate | sort | filter
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    window.console.log(pagination, filters, sorter);
    // getlists(filters)
  };

  // Pagination
  const paginationOnChange = (page: any, pageSize: any) => {
    if (intParams.pageSize !== pageSize) {// 切换size时从第一页开始
      setIntParams({
        currentPage: 1,
        pageSize
      })
      return
    }
    setIntParams({
      currentPage: page,
      pageSize,
    });
    // getlist();
  };

  // const summary = useCallback((pageData?: any): ReactNode => {
  //   if (!summaryFields) {
  //     return undefined
  //   }
  //   const o = {}
  //   pageData.forEach((item: any) => {
  //     summaryFields.reduce((bf: any, af: string) => {
  //       const { isNaN, parseInt } = Number
  //       const result = isNaN(parseInt(item[af], 20))
  //       bf[af] = (result ? 0 : parseInt(item[af], 20)) + (bf[af] ? bf[af] : 0)
  //       return bf
  //     }, o)
  //   })
  //   return (
  //     <Table.Summary.Row>
  //       {
  //         columns.map((item, i) => (
  //           <Table.Summary.Cell key={i} index={i}>
  //             {
  //               i === 0 && "合计："
  //             }
  //             {
  //               summaryFields.find(v => v === item.key) && (
  //                 <Text type="danger">{o[item.key as string]}</Text>
  //               )
  //             }
  //           </Table.Summary.Cell>
  //         ))
  //       }
  //     </Table.Summary.Row>
  //   )
  // }, [summaryFields])

  return (
    <>
      <EncTable
        dataSource={data.dataSource}
        columns={columns}
        onChange={tableOnChange}
        {...others} />
      {
        hasPagination && (
          <EncPagination total={data.total}
            showQuickJumper={showquickjump}
            pageSizeOptions={pageSizeOptions} current={intParams.currentPage} onChange={paginationOnChange} />
        )
      }
    </>
  );
}

// tslint:disable-next-line:no-null-keyword
export default connect(null, mapDispatchToProps)(Paging);
