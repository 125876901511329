import React, {
  FC, useEffect,
  //  useCallback,
  // useMemo
} from 'react';
import { Modal } from 'antd';
import style from "./style.less";
import store from '@/store';
import Watermark from '@uiw/react-watermark';

interface IProps {
  title?: string,
  visible: boolean,
  onOk?: () => void,
  onCancel?: (e: any) => void,
  setModalVisible: (visible: boolean) => void, // 父窗口点击mask调用关闭子modal
  confirmLoading?: boolean,
  footer?: any,
  closable?: boolean,
  destroyOnClose?: boolean,
  width?: string | number,
  okText?: string,
  className?: string,
  zIndex?: number,
  forceRender?: boolean,
  isWithWatermark?: boolean,
}

const top = window.top;

const addMask = () => {
  const id = Number(Math.random().toString().substr(3, 6)).toString(36);
  top.closeParentMask = (event: any) => { // top window 一直是iframe里面的对象  closeParentMask才是父窗口对象
    event?.preventdefault();
    event?.stopPropagation();

    const box = top.document.getElementById(id);
    box?.parentNode?.removeChild(box);
    let selfBox = window.document.getElementById(id);
    selfBox?.parentNode?.removeChild(selfBox);
    // tslint:disable-next-line: no-null-keyword
    selfBox = null;
    const callback = top.frames[0]?.setModalCallback;
    if (callback) { callback(false); }
    // callback && callback(false); // modal 关闭
  };
  // insertAdjacentHTML 不会破坏整体结构 为了时机同步
  window.requestAnimationFrame(() => {
    top.document.body.insertAdjacentHTML('beforeend', '<div class="ant-modal-mask" id="' + id + '" onclick="closeParentMask()"></div>');
    if (top !== window) {
      window.document.body.insertAdjacentHTML('beforeend', '<div class="ant-modal-mask" id="' + id + '" onclick="closeParentMask()"></div>');
    }
  });
}

const showMask = (visible: boolean) => {
  if (visible) {
    addMask();
  } else {
    if (top?.closeParentMask) { top?.closeParentMask(); }
    if (window?.closeParentMask) { window?.closeParentMask(); }
  }
}

window.setModalCallback = (visible: boolean) => (false)

const getWid = (win: any) => {
  let w = win.innerWidth;

  if (win.document.body && win.document.body.offsetWidth) {
    w = win.document.body.offsetWidth;
  }

  // tslint:disable-next-line:max-line-length
  if (win.document.compatMode === 'CSS1Compat' && win.document.documentElement && win.document.documentElement.offsetWidth) {
    w = win.document.documentElement.offsetWidth;
  }

  if (win.innerWidth && win.innerHeight) {
    w = win.innerWidth;
  }
  return w;
}

const EnModal: FC<IProps> = ({
  title,
  visible,
  children,
  onOk,
  onCancel,
  setModalVisible,
  footer,
  confirmLoading = false,
  closable = false,
  destroyOnClose = false,
  width = 520,
  okText = '确定',
  className,
  zIndex = 1000,
  forceRender = false,
  isWithWatermark = true
}) => {
  useEffect(
    () => {
      if (top !== window) { // 只有内嵌到别人时才执行，其他按默认处理
        showMask(visible);
        window.setModalCallback = setModalVisible;
      }
    },
    [visible],
  );

  return (
    <Modal
      width={width}
      title={title}
      visible={visible}
      onOk={onOk}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      // mask={false}
      footer={footer}
      closable={closable}
      destroyOnClose={destroyOnClose}
      okText={okText}
      className={`${style.modal} ${className}`}
      zIndex={zIndex}
      style={{
        left: top !== window ? '-6%' : '0',
        // left: top !== window ?
        //   `-${(getWid(top) - getWid(window)) / 2}px`
        //   : 0,
        top: top !== window ? (top.document.documentElement.scrollTop || top.document.body.scrollTop) + 60 : 100
      }}
      mask={top !== window ? false : true}
      forceRender={forceRender}
    >
      {isWithWatermark ? (
        <Watermark
          content={[`${store.getState().common.username}`, `${store.getState().common.psid}`]}
          rotate={-45}
          gapY={80}
          gapX={120}
          fontSize={16}
          fontColor="rgba(0, 0, 0, 0.1)"
          style={{ background: '#fff', width: '100%' }}>
          {
            children
          }
        </Watermark>
      ) : children}
    </Modal>
  );
}

export default EnModal;
