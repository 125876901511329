// iframe测试
import React from 'react';
// import { Modal } from 'antd';
import style from './style.less';
if (_FRENV_ === 'dev') {
  document.domain = 'hwwt2.com';
}

window.addEventListener("message", receiveMessage, false);

function receiveMessage(event: any) {
  const origin = event.origin || event.originalEvent.origin;
  if (!origin.match('hwwt2.com')) { return; }
  // console.log('父iframe：');
  // console.dir(event.data);
}

window.openMask = () => { // 属性需要d.ts上定义

}

function Iframe() {

  return (
    <div className='high-100' style={{ padding: '32px 0 0', overflowY: 'auto' }}>
      <iframe className={`${style.iframe}`} id='iframe_franchisee' src="http://b.hwwt2.com:9000/#/bill/chargeoffs" width="100%" height="100%" />
    </div>
  );
}

export default Iframe;
