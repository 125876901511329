import React, { useState, useEffect, ReactNode } from 'react';
import { Input } from 'antd';
import { InputProps } from "antd/lib/input";
export interface Props extends InputProps {
  width?: number | string,
  disabled?: boolean,
  placeholder?: string,
  defaultValue?: any,
  value?: any;// 目前form表单联动使用
  onChange?: (value: any) => void;// 目前form表单联动使用
}
const EncInput = ({ onChange, ...others }: Props) => {
  const triggerChange = (val: any) => {
    onChange?.(val);
  }

  return (
    <Input
      title={others.value}
      {
      ...others
      }
      onChange={(e) => triggerChange(e.target.value)}
    />
  )
}

export default EncInput
