// redux 测试
import React from 'react';
import Counter from '@/components/counter';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from './ducks/redux';

const mapStateToProps = (state: any) => ({
  ...state.modules.example.redux
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: {
    ...bindActionCreators(actions, dispatch),
  }
});

function Redux(props: any) {
  return (
    <Counter value={props.count} onIncrement={props.actions.increment} onDecrement={props.actions.decrement} />
  );
}

// 修饰符ts暂时有问题
export default connect(mapStateToProps, mapDispatchToProps)(Redux);
