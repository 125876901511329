// import Cache from './cache';
import axios from 'axios';
import store from '@/store';
import { GLOADING, EXPORTLOADING } from '@/store/actions';
import { message } from 'antd';
import { history } from '../App';

message.config({
  top: 100,
  duration: 4,
  maxCount: 1
});
let count = 0
const request = axios.create({
  baseURL: '/', // url = base url + request url
  timeout: 180000,
  // withCredentials: true, // 跨域 传session 不安全一般不设置
  headers: {
    'x-requested-with': 'XMLHttpRequest',
  }
});

// TODO 并发请求时，当且仅当最后返回时触发Success Action
request.interceptors.request.use((config: any) => {
  if (!config.noShowLoading) {
    count++
    window.console.log(`发送请求${count}:${config.url}`)
    store.dispatch({ type: GLOADING, payload: { gloading: true } });
  }
  store.dispatch({ type: EXPORTLOADING, payload: { exportloading: true } });
  if (config.params) {
    config.params._t = Date.now();
  } else {
    config.params = {
      _t: Date.now()
    };
  }
  config.headers.Token = top?.localStorage.getItem("fis-token-mup_temp")
  if (top === window) {
    const Authorization = top?.localStorage.getItem("Authorization")
    if (Authorization) {
      config.headers.Authorization = Authorization
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});


request.interceptors.response.use(response => {
  const res = response.data;
  const config: any = response.config;

  // // 缓存数据 TODO
  // if (config.method === 'get' && config.cache === true) {
  //   // caching(config, data);
  // }
  // if (!config.noShowLoading) {
  //   count--
  //   window.console.log(`${count}:${config.url}`)
  // }
  if (!config.noShowLoading) {
    count--
    window.console.log(`${count}:${config.url}`)
  }
  if (!config.noShowLoading && count <= 0) {
    store.dispatch({ type: GLOADING, payload: { gloading: false } });
  }
  if (!config.noInfos) {
    message.destroy()// Todo 这边存在问题
  }

  if (config.responseType !== 'arraybuffer' && !config.noInfos && (res.code !== 0 && res.code !== 200 && res.code !== "000000")) {// 判断非导出的情况
    message.error(res.message || res.msg || '接口异常');
  }
  if (config.responseType === 'arraybuffer') {
    const data = res
    if (response.headers['content-disposition']) {
      const fileName = decodeURIComponent(response.headers['content-disposition'].split('filename=')[1])
      let url = ''
      // if (response.headers['content-disposition'].slice(-3) === 'zip') {
      //   url = response.request.responseURL
      // } else {
      url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
      // }
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', config?.fileName || fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      const blob = new Blob([data])
      const reader = new FileReader()
      reader.readAsText(blob, 'utf-8')
      reader.onload = () => {
        const t: any = reader.result
        const { message: msg, code } = JSON.parse(t)
        if (!config.noInfos) {
          if (code === 0) {
            message.error('暂无需要导出的数据');
          } else {
            message.error(msg || '接口异常');
          }
        }
      }
    }
  }
  store.dispatch({ type: EXPORTLOADING, payload: { exportloading: false } });
  // 成功
  // if (process.env.NODE_ENV !== 'production') {
  //   return res;
  // }
  return res.data ? res.data : res
  // tslint:disable-next-line: align
}, (error) => {
  if (count > 0) {
    count--
  }
  store.dispatch({ type: GLOADING, payload: { gloading: false } });
  store.dispatch({ type: EXPORTLOADING, payload: { exportloading: false } });
  if (axios.isCancel(error)) {
    // 主动取消
    message.error('请求被取消!');
  } else {
    // 网络异常或服务器宕机
    const res = error.response.data;
    if (res.code === 401) {
      history.push(`/login/KFC?choseBrand=0`)
    }

    if (error.response.status === 413) {
      message.error('文件太大 请压缩文件再上传!');
    } else {
      message.error(res.message || res.msg || '网络繁忙!');
    }
  }
  return Promise.reject(undefined);
});

export default request;