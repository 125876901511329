import * as React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createHashHistory, History } from 'history'
import zhCN from 'antd/lib/locale/zh_CN';
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { ConfigProvider, Spin } from 'antd';
import { connect } from 'react-redux';
import routes from './config/routes';
import NoMatch from 'components/nomatch';
import Layout from 'components/layout';
import EnModal from "@/components/enModal";
import { hot } from 'react-hot-loader/root'// 配合HotModuleReplacementPlugin 热更新不刷新页面
import {
  queryAppendSalesListCopy
} from '@/api/income';
// import spinNest from 'utils/parantLoading';

let history: History<unknown>

dayjs.locale('zh-cn')
interface IProps {
  gloading: boolean,
}
let callback: any
class App extends React.Component<IProps> {
  public state = {
    visible: false
  };
  public onCancel = () => {
    if (!callback) {
      return
    } else {
      this.setState({ visible: false })
      const domLists: any = top?.document.getElementsByClassName("ant-menu-item")
      let findDom: any
      for (let i = 0, len = domLists.length; i < len; i++) {
        if (domLists[i].innerText === '日营业额查询') {
          findDom = domLists[i]
          break
        }
      }
      if (findDom) {
        findDom.click()
      }
      callback?.(false)
      callback = undefined
    }
  }
  public render() {
    const { gloading = false } = this.props;
    // spinNest(gloading);
    const getConfirmation = async (message: string, cb: (t: any) => void) => { // 至关重要的callback方法，可以异步执行
      const titles = {
        'rerecode': '您的数据未补充完毕，请确认是否关闭页面？'
      }
      const arr: string[] = message.split("|")
      if (arr[1] === 'true' && titles[arr[0]]) {
        const res: any = await queryAppendSalesListCopy({ pageNum: 1, pageSize: 1, operateStatus: '2' })
        if (!res.code && res.total > 0) {
          this.setState({ visible: true })
          callback = cb
        } else {
          cb(true)
        }
        //   Modal.confirm({
        //     title: titles[message],
        //     content: '',
        //     okText: '确认',
        //     cancelText: '取消',
        //     onOk() {
        //       callback(true);
        //     },
        //     onCancel() {
        //       callback(false);
        //     },
        //   });
        // } else {
        //   callback(true)
        // }
      } else {
        cb(true)
      }
    }

    if (!history) {
      history = createHashHistory({
        getUserConfirmation: getConfirmation
      })
    }

    return (
      <ConfigProvider locale={zhCN}>
        <Spin spinning={gloading} tip="加载中..." wrapperClassName="high-100" style={{ zIndex: 999999, maxHeight: 'none' }}>
          <Router history={history} >
            <Layout>
              <Switch>
                {routes.map((item) => (
                  <Route
                    key={item.path}
                    // exact={item.exact}
                    path={item.path}
                    component={item.component}
                  />
                ))}
                <Route path={"/"} exact render={() => <Redirect to={routes[0].path} />} />
                <Route component={NoMatch} />
              </Switch>
            </Layout>
          </Router>
        </Spin>
        <EnModal
          title=""
          visible={this.state.visible}
          onCancel={this.onCancel}
          onOk={() => {
            if (callback) {
              callback(true)
              callback = undefined
            }
            this.setState({ visible: false })
          }}
          destroyOnClose={true}
          closable={true}
          setModalVisible={this.onCancel}
        >您的数据未补充完毕，请确认是否关闭页面？</EnModal>
      </ConfigProvider >
    );
  }
}

const mapStateToProps = (state: any) => ({
  gloading: state.common.gloading,
});

// 修饰符ts暂时有问题
export default hot(connect(mapStateToProps)(App)); // 新版本已经自动引入
// export default connect(mapStateToProps)(App);
export { history }