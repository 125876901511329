import { Action } from '@/constants'

// PREFIX: MODULE/VIEW
const PREFIX = 'RECRUIT/REDUX';
// BUSINESS ACTIONS
export const RETRACT = `${PREFIX}/RETRACT`;

// ACTION CREATER
const actions = {

  setRetract: (type: string) => ({
    type: RETRACT,
    payload: type
  }),
};

const initData = {
  retract: false,// 头部默认展开
}
type StoreState = typeof initData
const reducer = (state = initData, action: Action): StoreState => {
  switch (action.type) {
    case RETRACT:
      return {
        ...state,
        retract: action.payload
      }
    default:
      return state
  }
}

export default reducer;
export { actions };
