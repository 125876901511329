import { Action } from '@/constants'
import {
  RESTAURANT, FRANCHISEE, SEARCHPARAMS
} from "./all";
// ACTION CREATER
const actions = {

  setRestaurantLists: (type: object) => ({
    type: RESTAURANT,
    payload: type
  }),

  setFranchiseesList: (type: object) => ({
    type: FRANCHISEE,
    payload: type
  }),

  setRecordRqtparams: (type: object) => ({
    type: SEARCHPARAMS,
    payload: type
  }),
};

const initData = {
  restaurantLists: {},// 主档的记录信息
  franchiseesList: {},// 主档的记录信息
  recordRqtparams: {}// 记录请求参数
}
type StoreState = typeof initData
const reducer = (state = initData, action: Action): StoreState => {
  switch (action.type) {
    case FRANCHISEE:
      return {
        ...state,
        franchiseesList: action.payload
      }
    case RESTAURANT:
      return {
        ...state,
        restaurantLists: action.payload
      }
    case SEARCHPARAMS:
      return {
        ...state,
        recordRqtparams: action.payload
      }
    default:
      return state
  }
}

export default reducer;
export { actions };
