import { AxiosRequest } from '@/constants';
import request from 'utils/request';

export const HOST = '';

const role_base_url = '/mininglamp-supervision/user';

// 用户管理列表
export function getUserList(data: any) {
  const params: AxiosRequest = {
    baseURL: role_base_url,
    url: '/user/list',
    method: 'post',
    data,
    noShowLoading: true,
  }
  return request(params);
}

let userHOST = '/mininglamp-infra/user';

// if (process.env.NODE_ENV !== 'production') {
//   userHOST = '/user'
// }
export function getUserInfos() {
  const params: AxiosRequest = {
    url: `${userHOST}/user/info`,
    method: 'get',
    params: {},
    noShowLoading: true,
  }
  return request(params);
}
export function getAccessToken() {
  return request({
    url: `${userHOST}/user/ids/accessToken`,
    method: 'get',
    params: {},
    noShowLoading: true,
  }as AxiosRequest);
}
export function userTestYunpan(params: any) {
  return request({
    url: `${userHOST}/user/testYunpan`,
    method: 'get',
    params,
    noShowLoading: true,
  }as AxiosRequest);
}

export function userYunpanAccessToken() {
  return request({
    url: `${userHOST}/user/yunpan/accessToken`,
    method: 'get',
    params:{},
    noShowLoading: true,
  }as AxiosRequest);
}



let fileHOST = '/mininglamp-infra/charge';

if (process.env.NODE_ENV !== 'production') {
  fileHOST = '/mininglamp-infra/charge'
}
// 文件上传 公用 返回url地址
export function fileUpload(data: any,flag=false) {
  return request({
    url: `${fileHOST}/charge/file/upload/changeFileName`,
    method: 'POST',
    data,
    noShowLoading:flag
  }as AxiosRequest);
}

// 上传多文件 公用
export function fileBatchUpload(data: any) {
  return request({
    url: `${fileHOST}/charge/file/batch-upload/changeFileName`,
    method: 'POST',
    data,
  });
}