import React, { useState, useEffect, ReactNode } from 'react';
import { Select } from 'antd';
import Base64 from "@/utils/base64c";
interface Option {
  code: any,
  label: string,
  disabled?: boolean
}
let searchParams: object = {}// 记录上一次参数
export interface Props {
  width?: number | string,
  mode?: "multiple" | "tags",
  disabled?: boolean,
  placeholder?: string,
  options?: Option[],
  selectrequest?: any,
  bordered?: boolean,
  defaultValue?: any,
  value?: any;// 目前form表单联动使用
  onChange?: (value: any) => void;// 目前form表单联动使用
  onSearch?: (value: any) => void,
  onFocus?: () => void,
  onBlur?: () => void,
  filterOption?: boolean,
  defaultActiveFirstOption?: boolean, // 是否默认高亮第一个选项
  showArrow?: boolean,// 展示箭头
  notFoundContent?: ReactNode// 没有结果展示无数据
  forwardRef?: any,
  dropdownClassName?: any
  dropdownStyle?: any,
  maxTagCount?: number,
  watchParams?: string[],
  replaceSql?: string,
  formvalues?: any[],
  selectHasAll?: boolean,
  speSearch?: boolean,// 自带过滤并数组塞入输入值
  className?: string
}
const EncSelect = ({ mode, disabled, placeholder, width, options = [], defaultValue, forwardRef, selectHasAll = false, speSearch = false,
  selectrequest, value, onChange, onSearch, watchParams = [], formvalues = [], replaceSql = '', ...others }: Props) => {
  const [SelectOptions, setSelectOptions] = useState<Option[]>([]);
  useEffect(() => {
    if (selectrequest && !watchParams.length) {
      selectrequest().then((res: any) => {
        setSelectOptions(res.map((item: any) => ({
          ...item,
          ...item.name && {
            label: item.name,
            code: item.value
          },
          ...item.text && {
            label: item.text,
            code: item.code
          }
        })))
      })
    }
  }, []);
  useEffect(() => {
    if (watchParams.length && replaceSql) {// 动态报表处理参数及调用时机
      const reprms: string = replaceSql
      let flag: boolean = true
      let needCg: boolean = false
      const datas: any = {
        // sql: Base64.encode(replaceSql)
      }
      for (const item of watchParams) {
        const vas = formvalues[1][item]
        if (!vas) {
          datas[`${item}`] = ''
        } else {
          flag = false
          datas[`${item}`] = vas
        }
        // if (!vas) {
        //   flag = true
        //   break
        // }
        if (!needCg) {
          needCg = Object.keys(formvalues[0]).includes(item)
        }
        // reprms = reprms.replace(`#{${item}}`, `#{para.${item}}`)
      }
      datas.sql = Base64.encode(reprms)
      // if(Object.keys())
      searchParams = datas
      if (!flag) {
        if (needCg) {
          if (value) {
            onChange?.('')
          }
        }
      }
      //     //     // searchParams = datas
      //     //     // window.console.log(replaceSql)
      //     //     // selectrequest(datas).then((res: any) => {
      //     //     //   setSelectOptions(res.map((item: any) => ({
      //     //     //     code: item.value,
      //     //     //     label: item.name
      //     //     //   })))
      //     if (value) {
      //       onChange?.('')
      //     }
      //     //     // })
      //   }
      // }
      // } else {
      //   if (value) {
      //     onChange?.('')
      //   }
      //   setSelectOptions([])
      // }
    }
  }, [watchParams, formvalues])
  useEffect(() => {
    if (speSearch && value === undefined) {
      setSelectOptions([])
    }
  }, [speSearch, value])
  const triggerChange = (val: any) => {
    let newval = val
    if (selectHasAll && newval === undefined) {// 增加全选时清空默认传空
      newval = ''
    }
    onChange?.(newval);
  }

  const triggerSearch = (val: any) => {
    if (speSearch && val) {
      const res = options.find((item: Option) => item.code === val)
      if (!res) {
        setSelectOptions([{ code: val, label: val }].concat(options))
      } else {
        setSelectOptions([])
      }
    } else if (SelectOptions.length) {
      // setSelectOptions([])
    } else {
      setSelectOptions([])
    }
    onSearch?.(val)
  }

  return (
    <Select allowClear
      ref={forwardRef}
      {
      ...others
      }
      defaultValue={defaultValue}
      showSearch
      mode={mode}
      disabled={disabled}
      placeholder={placeholder || '请选择'}
      filterOption={(input, option) =>
        option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      value={value}
      onSearch={triggerSearch}
      onChange={triggerChange}
      onDropdownVisibleChange={(open) => {
        if (watchParams.length && replaceSql && open) {
          selectrequest(searchParams).then((res: any) => {
            setSelectOptions(res.map((item: any) => ({
              code: item.value,
              label: item.name
            })))
            // if (value) {
            //   onChange?.('')
            // }
          })
        }
      }}
      style={{ width: width || '100%' }}>
      {
        SelectOptions.length ? (
          SelectOptions.map(v => (
            <Select.Option key={v.code} value={v.code}>{v.label}</Select.Option>
          ))
        ) : (
          options.map(v => (
            <Select.Option key={v.code} value={v.code} disabled={v.disabled}>{v.label}</Select.Option>
          ))
        )
      }
    </Select>
  )
}

export default EncSelect
