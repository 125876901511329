import { combineReducers } from 'redux';
import {
  USER, GLOADING, MENUS, PAGINATION, CRUMBS, POST, AUTHORITY, ALLDICT,
  USERACTINOS, RECEIVEUNIT, MASTERMARKET, ROLES, USERNAME, PSID, TRADER, ACCOUNT,
  EXPORTLOADING,RECRUIT
} from './actions';
import example from 'modules/example/ducks';
import chargestore from 'modules/charge/store';
import paysbill from 'modules/paysBill/store';
import report from 'modules/report/store';
import recruit from 'modules/recruit/store';

const initState = {
  post: '1',// 维护岗 -> 1 确认岗 -> 2
  authority: '1',// 1.Onboard专员 2.支持经理 3.加盟部收费项清单确认岗
  trader: '1',// 1.加盟商 2.支持经理
  gloading: false,
  exportloading:false,
  crumbs: {},
  userid: '',
  username: '',
  account:'',
  psid: '',
  menu: [],
  allDicts: [],
  pageParams: {
    currentPage: 1,
    pageSize: 10
  },
  userActions: {},// 
  roles: {},// 用户角色 101:财务收费主档维护岗 102：财务收费主档确认岗 
  receiveUnits: [],// 收费主体列表
  masterMarket: [],// 加盟主档市场相关
  recruitdatas:[]
};

const commonReducer = (state = initState, action: { type: string, payload: any }) => {
  switch (action.type) {
    case USER:
      return {
        ...state,
        ...action.payload
      };
    case GLOADING:
      return {
        ...state,
        ...action.payload
      };
    case EXPORTLOADING:
      return {
        ...state,
        ...action.payload
      };
    case CRUMBS:
      return {
        ...state,
        ...action.payload
      };
    case MENUS:
      return {
        ...state,
        menu: action.payload.menus
      };
    case PAGINATION:
      return {
        ...state,
        pageParams: action.payload
      };
    case POST:
      return {
        ...state,
        post: action.payload
      }
    case AUTHORITY:
      return {
        ...state,
        authority: action.payload
      }
    case TRADER:
      return {
        ...state,
        trader: action.payload
      }
    case ACCOUNT:
      return {
        ...state,
        account: action.payload
      }
    case ALLDICT:
      return {
        ...state,
        allDicts: action.payload || []
      }
    case USERACTINOS:
      return {
        ...state,
        userActions: action.payload || {}
      }
    case ROLES:
      return {
        ...state,
        roles: action.payload || {}
      }
    case USERNAME:
      return {
        ...state,
        username: action.payload || {}
      }
    case PSID:
      return {
        ...state,
        psid: action.payload || {}
      }
    case RECEIVEUNIT:
      return {
        ...state,
        receiveUnits: action.payload
      }
    case MASTERMARKET:
      return {
        ...state,
        masterMarket: action.payload
      }
      case RECRUIT:
        return {
          ...state,
          recruitdatas: action.payload
        }
    default:
      return state;
  }
};

// 状态管理（按需要添加）
const reducer = combineReducers({
  // 公共
  common: commonReducer,
  // 具体业务
  modules: combineReducers({
    example,
    chargestore,
    paysbill,
    report,
    recruit
  }),
});

export default reducer;
