// 收费项配置模块
import request from 'utils/request';
import qs from 'qs';
import { AxiosRequest } from '@/constants';

let HOST = '/mininglamp-infra/charge';

if (process.env.NODE_ENV !== 'production') {
  HOST = '/mininglamp-infra/charge'
}

export function getCodeAll() {
  return request({
    url: `${HOST}/code/all`,
    method: 'GET',
    params: {},
  });
}

export function getCodeList(params: any) {
  return request({
    url: `${HOST}/code/list`,
    method: 'GET',
    params,
    noInfos: true,
    noShowLoading: true
  } as AxiosRequest);
}

// 收费项主档
export function getChargeConfirm(data: any) {// 收费项确认
  return request({
    url: `${HOST}/charge_item/confirm`,
    method: 'POST',
    data,
  });
}
export function detailChargeItem(params: any) {// 收费项确认
  return request({
    url: `${HOST}/charge_item/detail`,
    method: 'get',
    params,
  });
}
export function receiveUnitChargeItem() {// 查询收费主体列表
  return request({
    url: `${HOST}/charge_item/receive_unit`,
    method: 'get',
    params: {},
    noShowLoading: true
  } as AxiosRequest);
}
export function getChargeDelete(params: any) {// 收费项删除
  const param: AxiosRequest = {
    url: `${HOST}/charge_item/delete`,
    method: 'DELETE',
    params,
    noShowLoading: true
  }
  return request(param);
}
export function getChargeSubmit(data: any) {// 收费项提交
  return request({
    url: `${HOST}/charge_item/submit`,
    method: 'POST',
    data,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });
}
export function getChargeHistory(params: any) {// 收费项对应已生成数据
  return request({
    url: `${HOST}/charge_item/history`,
    method: 'GET',
    params,
  });
}
export function copyChargeItem(params: any) {// 收费项列表导出
  const param: AxiosRequest = {
    url: `${HOST}/charge_item/copy`,
    method: 'get',
    params,
    noShowLoading: true
  };
  return request(param);
}
export function backoffChargeItem(params: any) {// 收费项列表导出
  const param: AxiosRequest = {
    url: `${HOST}/charge_item/backoff`,
    method: 'get',
    params,
    noShowLoading: true
  };
  return request(param);
}
export function getChargeExport(data: any) {// 收费项列表导出
  const param: AxiosRequest = {
    url: `${HOST}/charge_item/export`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
    noShowLoading: true
  };
  return request(param);
}
export function getChargeExportAll(data: any) {// 收费项全部导出
  const param: AxiosRequest = {
    url: `${HOST}/charge_item/export/all`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
    noShowLoading: true
  };
  return request(param);
}
export function getChargeExportAlldetail(params: any) {// 收费项全部导出
  const param: AxiosRequest = {
    url: `${HOST}/charge_item/export/alldetail`,
    method: 'get',
    params,
    responseType: 'arraybuffer',
    noShowLoading: true
  };
  return request(param);
}
export function getChargeSave(data: any) {// 收费项保存
  return request({
    url: `${HOST}/charge_item/save`,
    method: 'POST',
    data,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });
}
export function disabledChargeItem(params: any) {// 收费项停用
  return request({
    url: `${HOST}/charge_item/disabled`,
    method: 'delete',
    params,
  });
}
export function getChargeSearch(data: any) {// 收费项列表查询
  return request({
    url: `${HOST}/charge_item/search`,
    method: 'POST',
    data,
  });
}

// // 保存收费标准
// export function saveStandard(data: any) {
//   return request({
//     url: `${HOST}/standard/save`,
//     method: 'post',
//     data,
//     headers: {
//       'Content-type': 'multipart/form-data'
//     }
//   } as AxiosRequest);
// }

// // 提交收费标准
// export function submitStandard(data: any) {
//   return request({
//     url: `${HOST}/standard/submit`,
//     method: 'post',
//     data,
//     headers: {
//       'Content-type': 'multipart/form-data'
//     }
//   } as AxiosRequest);
// }
// 保存收费标准
export function saveStandard(data: any) {
  return request({
    url: `${HOST}/standard/saveNew`,
    method: 'post',
    data,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  } as AxiosRequest);
}

// 保存收费标准
export function confirmSaveStandard(data: any) {
  return request({
    url: `${HOST}/standard/confirmSave`,
    method: 'post',
    data,
  });
}

export function standardsaveByAdmin(data: any) {
  return request({
    url: `${HOST}/standard/saveByAdmin`,
    method: 'post',
    data,
  });
}

export function confirmByAdmin(data: any) {
  return request({
    url: `${HOST}/standard/confirmByAdmin`,
    method: 'post',
    data,
  });
}


export function deleteByAdmin(data: any) {
  return request({
    url: `${HOST}/standard/deleteByAdmin`,
    method: 'post',
    data,
  });
}


// 提交收费标准
export function submitStandard(data: any) {
  return request({
    url: `${HOST}/standard/submitNew`,
    method: 'post',
    data,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  } as AxiosRequest);
}

// 确认收费标准
export function confirmStandard(params: any) {
  return request({
    url: `${HOST}/standard/confirm`,
    method: 'put',
    params,
  });
}

// 删除收费标准
export function deleteStandard(params: any) {
  return request({
    url: `${HOST}/standard/delete`,
    method: 'delete',
    params,
  });
}

// 停用收费标准
export function disabledStandard(params: any) {
  return request({
    url: `${HOST}/standard/disabled`,
    method: 'delete',
    params,
  });
}

// 退回收费标准
export function backoffStandard(params: any) {
  return request({
    url: `${HOST}/standard/backoff`,
    method: 'get',
    params,
  });
}

// 收费标准模板列表导出
export function exportStandard(data: any) {
  return request({
    url: `${HOST}/standard/export`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
    noShowLoading: true
  } as AxiosRequest);
}

// 选择收费项创建模板 - 收费项列表导出
export function exportStandardItem(data: any) {
  return request({
    url: `${HOST}/standard/item_export`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
    noShowLoading: true
  } as AxiosRequest);
}

export function exportNewStandardItem(data: any) {
  return request({
    url: `${HOST}/standard/item_exportNew`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
    noShowLoading: true
  } as AxiosRequest);
}

export function exportBusinessChargeCorePreview(params: any) {
  return request({
    url: `${HOST}/standard/exportBusinessChargeCorePreview`,
    method: 'get',
    params,
    responseType: 'arraybuffer',
    // noShowLoading: true
  } as AxiosRequest);
}

// 查询收费项对应收费标准
export function standardItemStandard(params: any) {
  return request({
    url: `${HOST}/standard/item_standard`,
    method: 'get',
    params,
    paramsSerializer: (paras: any) => {
      return qs.stringify(paras, { arrayFormat: 'repeat' })
    }
  });
}

// 选择收费项创建模板 - 查询有效收费项信息
export function standardListItem(data: any) {
  return request({
    url: `${HOST}/standard/list_item`,
    method: 'post',
    data,
  });
}

// 选择收费项创建模板 - 查询有效收费项信息
export function standardItemStandardNew(data: any) {
  return request({
    url: `${HOST}/standard/item_standardNew`,
    method: 'post',
    data,
  });
}

// 查询收费标准详情
export function getStandardInfo(params: any) {
  return request({
    url: `${HOST}/standard/info`,
    method: 'get',
    params,
  });
}

// 复制收费标准详情
export function newLineStandard(data: any) {
  return request({
    url: `${HOST}/standard/new_line`,
    method: 'post',
    data,
    noShowLoading: true
  } as AxiosRequest);
}

// 复制收费标准详情
export function copyStandardInfo(params: any) {
  return request({
    url: `${HOST}/standard/copy`,
    method: 'get',
    params,
  });
}

// 查询收费标准列表
export function getStandardLists(data: any) {
  return request({
    url: `${HOST}/standard/list`,
    method: 'post',
    data,
  });
}

// 查询使用餐厅收费项清单
export function queryBusinessChargeCorePreview(data: any) {
  return request({
    url: `${HOST}/standard/queryBusinessChargeCorePreview`,
    method: 'post',
    data,
    noShowLoading: true,
    noInfos: true
  } as AxiosRequest);
}
export function previewStatusSave(data: any) {
  return request({
    url: `${HOST}/standard/previewStatusSave`,
    method: 'post',
    data,
  });
}
export function batchRetreiveWhiteReceiveUnitNo(data: any) {
  return request({
    url: `${HOST}/business_charge/batchRetreiveWhiteReceiveUnitNo`,
    method: 'post',
    data,
    noShowLoading: true,
    noInfos: true
  } as AxiosRequest);
}

// 收费模式确认
export function confirmChargeMode(params: any) {
  return request({
    url: `${HOST}/charge_mode/confirm`,
    method: 'put',
    params,
  });
}

// 收费模式详情
export function detailChargeMode(params: any) {
  return request({
    url: `${HOST}/charge_mode/detail`,
    method: 'get',
    params,
  });
}

// 收费模式删除
export function deleteChargeMode(params: any) {
  return request({
    url: `${HOST}/charge_mode/delete`,
    method: 'delete',
    params,
  });
}

// 收费模式提交
export function submitChargeMode(data: any) {
  return request({
    url: `${HOST}/charge_mode/submit`,
    method: 'post',
    data,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });
}

export function saveByAdmin(data: any) {
  return request({
    url: `${HOST}/charge_mode/saveByAdmin`,
    method: 'POST',
    data,
  });
}

// 模糊查询收费项名称
export function chargeNameFuzzy(params: any) {
  return request({
    url: `${HOST}/charge_mode/charge_name_fuzzy`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

// 通过收费项编码查询生效收费项信息
export function chargeType(params: any) {
  return request({
    url: `${HOST}/charge_mode/charge_type`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

// 通过收费项名称查询生效收费项信息
export function chargeName(params: any) {
  return request({
    url: `${HOST}/charge_mode/charge_name`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

// 查询所有已确认收费模式名称
export function chargeAllConfirmed(params: any) {
  return request({
    url: `${HOST}/charge_mode/all_confirmed`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

// 模糊查询收费项编码
export function chargeTypeFuzzy(params: any) {
  return request({
    url: `${HOST}/charge_mode/charge_type_fuzzy`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

// 收费模式保存
export function saveChargeMode(data: any) {
  return request({
    url: `${HOST}/charge_mode/save`,
    method: 'post',
    data,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });
}

// 收费模式列表查询
export function searchChargeModeLists(data: any) {
  return request({
    url: `${HOST}/charge_mode/search`,
    method: 'post',
    data,
  });
}


// 收费模式导出
export function exportChargeMode(data: any) {
  return request({
    url: `${HOST}/charge_mode/export`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
    noShowLoading: true
  } as AxiosRequest);
}

// 收费模式停用
export function disabledChargeMode(params: any) {
  return request({
    url: `${HOST}/charge_mode/disabled`,
    method: 'delete',
    params,
  });
}

export function allTerminatedChargeMode(params: any) {
  return request({
    url: `${HOST}/charge_mode/all_terminated`,
    method: 'get',
    params,
  });
}

// 查询收费模式对应的收费标准
export function searchChargeModeStandard(data: any) {
  return request({
    url: `${HOST}/charge_mode_standard/search`,
    method: 'post',
    data,
  });
}

// 查询收费模式对应的收费标准
export function exportChargeModeStandard(data: any) {
  return request({
    url: `${HOST}/charge_mode_standard/export`,
    method: 'post',
    data,
    noShowLoading: true,
    responseType: 'arraybuffer',
  } as AxiosRequest);
}

// 查询收费模式对应的收费标准
export function allChargeCondition(params: any) {
  return request({
    url: `${HOST}/charge_condition/all`,
    method: 'get',
    params,
    noShowLoading: true,
  } as AxiosRequest);
}

// 通过收费项ID、适用品牌和收费标准类型查询预设收费条件
export function findChargeCondition(data: any) {
  return request({
    url: `${HOST}/charge_condition/find`,
    method: 'post',
    data,
    noShowLoading: true,
  } as AxiosRequest);
}

// 预设收费条件删除
export function deleteChargeCondition(data: any) {
  return request({
    url: `${HOST}/charge_condition/delete`,
    method: 'delete',
    data,
    noShowLoading: true,
  } as AxiosRequest);
}

// 预设收费条件删除
export function saveChargeCondition(data: any) {
  return request({
    url: `${HOST}/charge_condition/save`,
    method: 'post',
    data,
    noShowLoading: true,
  } as AxiosRequest);
}

// 预设收费条件删除
export function listChargeCondition(data: any) {
  return request({
    url: `${HOST}/charge_condition/list`,
    method: 'post',
    data,
  });
}

// 查询所有收费条件码表 加盟餐厅属性使用
export function allChargeField(params: any) {
  return request({
    url: `${HOST}/charge_field/all`,
    method: 'get',
    params,
    noShowLoading: true,
  } as AxiosRequest);
}

// 分页查询加盟餐厅模式列表
export function collectionBusiness(data: any) {
  return request({
    url: `${HOST}/business/collection`,
    method: 'post',
    data,
  });
}

// 餐厅绑定收费模式
export function bindingBusiness(data: any) {
  return request({
    url: `${HOST}/business/binding`,
    method: 'post',
    data,
  });
}

// 餐厅绑定收费模式
export function batchBindingBusiness(data: any) {
  return request({
    url: `${HOST}/business/batch/binding`,
    method: 'put',
    data,
    noInfos: true,
    noShowLoading: true
  } as AxiosRequest);
}

// 餐厅绑定收费模式
export function exportBusiness(data: any) {
  return request({
    url: `${HOST}/business/export`,
    method: 'post',
    data,
    noShowLoading: true,
    responseType: 'arraybuffer',
  } as AxiosRequest);
}

// 餐厅绑定收费模式
export function exportBusinessCharge(data: any) {
  return request({
    url: `${HOST}/business_charge/export`,
    method: 'post',
    data,
    noShowLoading: true,
    responseType: 'arraybuffer',
  } as AxiosRequest);
}

// 查询加盟餐厅收费项清单详细
export function standardsBusinessCharge(data: any) {
  return request({
    url: `${HOST}/business_charge/standards`,
    method: 'post',
    data,
    noShowLoading: true,
  } as AxiosRequest);
}

// 删除餐厅收费标准
export function deleteBusinessCharge(data: any) {
  return request({
    url: `${HOST}/business_charge/delete_business_charge`,
    method: 'delete',
    data,
    noShowLoading: true,
  } as AxiosRequest);
}

// 删除餐厅收费标准
export function historyBusinessCharge(unitno: any) {
  return request({
    url: `${HOST}/business_charge/history/${unitno}`,
    method: 'get',
    params: {},
    noShowLoading: true,
  } as AxiosRequest);
}

// 批量确认
export function batchSubmitBusinessCharge(unitno: any, data: any) {
  return request({
    url: `${HOST}/business_charge/batch/${unitno}/submit_business_charge`,
    method: 'put',
    data
  });
}

// 批量确认
export function batchSaveBusinessCharge(unitno: any, data: any) {
  return request({
    url: `${HOST}/business_charge/batch/${unitno}/save_receive_no`,
    method: 'put',
    data
  });
}

// 模式下的收费项
export function searchBusinessCharges(unitno: any, name: string) {
  return request({
    url: `${HOST}/business_charge/charges/${unitno}/${name}`,
    method: 'get',
    params: {}
  });
}

// 全量查询，模式下的收费项
export function searchAllBusinessCharges(unitno: any) {
  return request({
    url: `${HOST}/business_charge/charge/${unitno}`,
    method: 'get',
    params: {},
    noShowLoading: true
  } as AxiosRequest);
}

// 根据收款主体唯一标识查询百胜白名单收款主体开票单位公司名称
export function getReceiveUnitNoOptions(receive_unit_no: any, unitno: any) {
  return request({
    url: `${HOST}/business_charge/receive/${receive_unit_no}/${unitno}`,
    method: 'get',
    params: {},
    noShowLoading: true
  } as AxiosRequest);
}

// 根据收款主体唯一标识查询百胜白名单收款主体开票单位公司名称
export function getCollectionUnitNo(business_unit_no: any) {
  return request({
    url: `${HOST}/business/collection/${business_unit_no}`,
    method: 'get',
    params: {},
  });
}

// 保存单条收费标注
export function saveBusinessCharge(data: any) {
  return request({
    url: `${HOST}/business_charge/update_business_charge`,
    method: 'put',
    data
  });
}


// 保存单条收费标注
export function batchConfirmBusinessCharge(unitno: any, data: any) {
  return request({
    url: `${HOST}/business_charge/batch/${unitno}/confirm_business_charge`,
    method: 'put',
    data
  });
}

// 时间窗口查询
export function timeWindowSearch(data: any) {
  return request({
    url: `${HOST}/time_window/search`,
    method: 'post',
    data,
  });
}

// 时间窗口保存
export function timeWindowSave(data: any) {
  return request({
    url: `${HOST}/time_window/save`,
    method: 'post',
    data,
  });
}

// 时间窗口导出
export function timeWindowExport(data: any) {
  return request({
    url: `${HOST}/time_window/export`,
    method: 'post',
    responseType: 'arraybuffer',
    data,
  });
}

// 时间窗口导入
export function timeWindowImport(data: any) {
  return request({
    url: `${HOST}/time_window/import`,
    method: 'POST',
    data,
    headers: {
      'Content-type': 'multipart/form-data'
    }
  });
}

export function standardBusiness(data: any) {
  return request({
    url: `${HOST}/standard/business`,
    method: 'post',
    data,
  });
}

export function getRelationCode(params: any) {
  return request({
    url: `${HOST}/code/relation_code`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

export function getRelationCodeSlave(params: any) {
  return request({
    url: `${HOST}/code/relation_code_slave`,
    method: 'get',
    params,
    noShowLoading: true
  } as AxiosRequest);
}

export function getCodePageList(data: any) {
  return request({
    url: `${HOST}/code/page/list`,
    method: 'post',
    data,
  });
}

export function stlCodeDetail(data: any) {
  return request({
    url: `${HOST}/code/save/stlCodeDetail`,
    method: 'post',
    data,
    noInfos: true,
    noShowLoading: true
  } as AxiosRequest);
}

export function delstlCodeDetail(data: any) {
  return request({
    url: `${HOST}/code/batchDelete/stlCodeDetail`,
    method: 'post',
    data,
    noInfos: true,
    noShowLoading: true
  } as AxiosRequest);
}
export function delcodeFiles(data: any) {
  return request({
    url: `${HOST}/code/delFiles`,
    method: 'post',
    data,
    noInfos: true,
    noShowLoading: true
  } as AxiosRequest);
}

export function getBatchquery(params: any) {
  return request({
    url: `${HOST}/business/batch/query`,
    method: 'get',
    params,
    noInfos: true,
    noShowLoading: true
  } as AxiosRequest);
}

export function getBatchUpdate(data: any) {
  return request({
    url: `${HOST}/business/batch/update`,
    method: 'put',
    data,
    noInfos: true,
    noShowLoading: true
  } as AxiosRequest);
}

export function getBatchSubmit(data: any) {
  return request({
    url: `${HOST}/business/batch/submit`,
    method: 'put',
    data,
    noInfos: true,
    noShowLoading: true
  } as AxiosRequest);
}

export function getBatchConfirm(data: any) {
  return request({
    url: `${HOST}/business/batch/confirm`,
    method: 'put',
    data,
    noInfos: true,
    noShowLoading: true
  } as AxiosRequest);
}

export function getReceiveQuery(status: string) {
  return request({
    url: `${HOST}/business_charge/receive/query/${status}`,
    method: 'get',
    params: {},
    noInfos: true,
    noShowLoading: true
  } as AxiosRequest);
}

export function getBatchExportAll(params: any) {
  return request({
    url: `${HOST}/business/batch/export_all`,
    method: 'get',
    params,
    responseType: 'arraybuffer',
  } as AxiosRequest);
}

export function getModeNote(business_unit_no: any) {
  return request({
    url: `${HOST}/business/mode_note/${business_unit_no}`,
    method: 'get',
    params: {},
    noShowLoading: true
  } as AxiosRequest);
}

export function getModeHist(business_unit_no: any, charge_no: string) {
  return request({
    url: `${HOST}/business/mode_hist/${business_unit_no}/${charge_no}`,
    method: 'get',
    params: {},
    noShowLoading: true
  } as AxiosRequest);
}
// 收费模式导出
export function exportAllChargeMode(data: any) {
  return request({
    url: `${HOST}/charge_mode/export/all`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
  } as AxiosRequest);
}
export function exportAlldetailChargeMode(params: any) {
  return request({
    url: `${HOST}/charge_mode/export/alldetail`,
    method: 'get',
    params,
    responseType: 'arraybuffer',
  } as AxiosRequest);
}

export function chargeFieldProvincecity(params: any) {// 省份码表
  return request({
    url: `${HOST}/charge_field/province_city`,
    method: 'get',
    params,
    noShowLoading: true,
  } as AxiosRequest);
}
// 营运支持服务费
export function exportSupportservicefee(data: any) {
  return request({
    url: `${HOST}/business/supportservicefee/export`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}
export function exportAllsupportservicefee(data: any) {
  return request({
    url: `${HOST}/business/supportservicefee/exportAll`,
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  });
}
export function saveSupportServiceFeeDate(data: any) {
  return request({
    url: `${HOST}/business/batch/saveSupportServiceFeeDate`,
    method: 'post',
    data,
  });
}
export function getSupportServiceFeeDateMng(data: any) {
  return request({
    url: `${HOST}/business/getSupportServiceFeeDateMng`,
    method: 'post',
    data,
  });
}
export function ifQueryBusinessWithSupportServiceFee(data: any) {
  return request({
    url: `${HOST}/business/ifQueryBusinessWithSupportServiceFee`,
    method: 'post',
    data,
  });
}
export function delFiles(data: any) {
  return request({
    url: `${HOST}/business/supportservicefee/delFiles`,
    method: 'POST',
    data,
  });
}
export function delBusinessChargeCoreFiles(data: any) {
  return request({
    url: `${HOST}/business_charge/delBusinessChargeCoreFiles`,
    method: 'POST',
    data,
  });
}
// tslint:disable-next-line:max-file-line-count
