import isFSA from './fsa';
import dayjs from "dayjs";
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import store from '@/store';
import { getAccessToken } from '@/api'

export function getParams(search = '') {
  const ps = search.substr(1);
  const par = ps.split('&');
  const params = {};
  par.forEach(item => {
    const ar = item.split('=');
    params[ar[0]] = decodeURIComponent(ar[1]);
  });
  return params;
}

export function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function setKey(arrs = []) {
  return arrs.map((item: { key?: any }, index) => {
    item.key = index + 1;
    return item;
  });
}

export function setFormData(params: any) {
  const fd = new FormData();
  const arrs = Object.keys(params);
  arrs.forEach(key => {
    if (params[key] === undefined) {
      return;
    }
    fd.append(key, params[key]);
  });
  return fd;
}

// 数字‘,’隔开显示
export function toThousands(num: any, need = true) {
  if (num === null || num === undefined) {
    return ''
  }
  let valueNode = '';
  const val = String(typeof num === 'number' ? num.toFixed(2) : num);
  const cells = val.match(/^(-?)(\d*)(\.(\d+))?$/)
  if (!cells || val === '-') {
    valueNode = val;
  } else {
    let int = cells[2] || '0';
    const decimal = cells[4] || '';
    int = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (decimal) {
      valueNode = int + '.' + decimal
    } else {
      valueNode = int + '.00'
    }
  }
  if (need) {// 需要符号
    return num >= 0 ? valueNode : '-' + valueNode
  }
  return valueNode// (num || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function pidToTree(list: any) {
  const lt = cloneDeep(list);
  const objTree = {};
  const treeResult: any[] = [];
  lt.forEach((item: { id: any; }) => {
    objTree[`${item.id}`] = item;
  });
  lt.forEach((item: { pid: any; }) => {
    if (item.pid) {
      objTree[`${item.pid}`].children ?
        objTree[`${item.pid}`].children.push(item) : objTree[`${item.pid}`].children = [item];
    } else {
      treeResult.push(item);
    }
  });
  return treeResult;
}

export function searchCheck(keyword: string) {
  let msg = '';
  let cha = keyword.replace(/\*|\?/g, '');
  cha = cha.replace(/[\u4e00-\u9fa5]/g, 'aa');
  if (!keyword) {
    msg = '请输入搜索内容';
  } else if (keyword.indexOf('*') === 0) {
    msg = '不以*号开头';
  } else if (cha.length < 3) {
    msg = '输入的关键字太少，请多输入几位';
  }

  return msg;
}

export function getSelfId() {
  return Math.random().toString(16).slice(2)
}

export function filterData(data: any[], key: string, value: string) {// 过滤字典返回
  return data?.filter(v => v[key] === value)
}

export function getSelectlabel(data: any[], code: string | string[]) {
  if (typeof code === 'string') {
    const hasObj = data.find(v => v.code === code)
    return hasObj ? hasObj.label : ''
  } else if (Array.isArray(code)) {
    const result = code.reduce((bf: string[], af: string) => {
      const hasObj = data.find(v => v.code === af)
      if (hasObj) {
        bf.push(hasObj.label)
      }
      return bf
    }, [])
    return result.join(",")
  }
}

export function formatDate(time: any) {// 格式化时间，后台传输均用此格式
  // tslint:disable-next-line:no-null-keyword
  return time ? Math.floor(dayjs(time).valueOf() / 1000) : null
}
export const timeFormat = {
  dateMinute: 'YYYY-MM-DD HH:mm',
  dateTime: 'YYYY-MM-DD HH:mm:ss',
  dateTimeSlash: 'YYYY/MM/DD HH:mm:ss',
  date: 'YYYY-MM-DD',
  dateYear: 'YYYY',
  dateMonth: 'YYYY/MM',
  dateMonthDay: 'YYYY/MM/DD',
  dateRequestMonth: 'YYYYMM',
  dateWeek: 'dddd',
  time: 'HH:mm:ss',
  dateTimeNoSeparator: 'YYYYMMDDHHmmss',
  dateNoSeparator: 'YYYYMMDD',
  month: 'MM'
}
export function formatTime(time: any, format = 'YYYY-MM-DD HH:mm:ss') {// 格式化时间，页面展示显示调用
  // tslint:disable-next-line:no-null-keyword
  return time ? format === '' ? dayjs(time * 1000) : dayjs(time * 1000).format(format) : null
}

export function formatNormalTime(time: any, format: keyof typeof timeFormat) {
  // tslint:disable-next-line:no-null-keyword
  return time ? dayjs(time).format(timeFormat[format]) : null
}

export function isHavaAuthority(code: any, rolecode = '', isFan = false) {
  if (_FRENV_ === 'online') {// uat 环境
    const userActions: any = store.getState().common.userActions
    const roles: any = store.getState().common.roles
    let flag = false
    if (userActions && Object.keys(userActions).length) {
      const res: string[] = rolecode.split("|")
      const meetcondi = res.some((code: string) => roles[code])
      flag = (code === true || userActions[code]) && (isFan ? !meetcondi : meetcondi)
    }
    return flag
  }
  return code
}

export function isJoinTrader() {
  if (_FRENV_ === 'online') {// uat 环境
    const roles: any = store.getState().common.roles
    let flag = false
    if (roles && Object.keys(roles).length) {
      flag = !roles['26']
    }
    return flag
  }
  return true
}

// 获取当前月的第一天
export function getCurrentMonthFirst(date: Date) {
  date.setDate(1);
  return date;
}

// 获取当前月的最后一天
export function getCurrentMonthLast(date: Date) {
  let currentMonth = date.getMonth();
  const nextMonth = ++currentMonth;
  const nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1).getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  return new Date(nextMonthFirstDay - oneDay);
}

export function getTableRender(allDicts: any[], type: string, val: any) {// 获取字典表中数据
  const datas = filterData(allDicts, 'type', type)
  // if (typeof val === 'string') {
  return getSelectlabel(datas, val)
  // }
  // return val.map((v: any) => getSelectlabel(datas, v))
}

export function assign(bf: object, af: object) {
  return Object.assign({ ...bf }, JSON.parse(JSON.stringify(af)))
}

export function debounce(fn: any, delay = 500) {
  // tslint:disable-next-line:no-null-keyword
  let timer: any = null // 声明计时器
  return (...args: any) => {
    // const context: any = this
    clearTimeout(timer)
    timer = setTimeout(() => {
      // tslint:disable-next-line:no-null-keyword
      fn.call(null, ...args)
    }, delay)
  }
}

export function callBackMarket(masterMarket: any, exterDs: any, type = 'brand', needdeal = false) {
  const sj: any = {};
  let options: any
  if (exterDs[type]) {
    options = masterMarket.filter((item: any) => item.filter === exterDs[type])
  } else {
    if (!needdeal) {
      options = masterMarket.reduce((a: any, b: any) => {// 过滤两者重复的
        if (!sj[b.code]) {
          sj[b.code] = true
          a.push(b)
        }
        return a
      }, [])
    } else {
      masterMarket.reduce((a: any, b: any) => {// 过滤两者重复的
        if (!sj[b.label]) {
          sj[b.label] = { ...b }
        } else {
          sj[b.label].code = `${b.code},${sj[b.label].code}`
        }
        return a
      }, [])
      options = Object.values(sj)
      // window.console.log(options)
    }
  }
  return options
}

export function callBackMarket2(masterMarket: any, exterDs: any, type = 'brand') { // 多选的情况
  let sj: any = {};
  let options: any
  const ans: any = []
  if (exterDs[type] && exterDs[type].length) {
    for (const item of exterDs[type]) {
      sj[item] = true
    }
    options = masterMarket.filter((item: any) => sj[item.filter]).map((item: any) => ({ ...item }))
    sj = {}
    for (const item of options) {
      if (!sj[item.label]) {
        sj[item.label] = item
        ans.push(item)
      } else {
        sj[item.label].code = `${sj[item.label].code},${item.code}`
      }
    }
  } else {
    options = masterMarket.map((item: any) => ({ ...item }))
    for (const item of options) {
      if (!sj[item.label]) {
        sj[item.label] = item
        ans.push(item)
      } else {
        sj[item.label].code = `${sj[item.label].code},${item.code}`
      }
    }
  }
  return ans
}

export function callBackZCLX(datas: any, exterDs: any, type = 'brand') {
  let options: any = []
  if (exterDs[type]) {
    options = datas.filter((item: any) => item.filter === exterDs[type])
  }
  return options
}

export function domChange() {
  const frah = document.getElementById('fra_hidden')
  if (frah) {
    document.body.removeChild(frah)
  }
  const div = document.createElement('div')
  div.id = 'fra_hidden'
  document.body.appendChild(div)
}

export function conversionDatas(datas: object) {
  return Object.entries(datas).map((item: any[]) => ({
    code: item[0],
    label: item[1]
  }))
}

export function createYearArray(current: any, year = 2020, yearnum = 20) {// 创建今年到固定年份的数据列表
  const currentYear = current.format("YYYY")
  const yearOptions: any[] = [
    { code: currentYear, label: currentYear }
  ]
  const futureyearOptions: any[] = [
    { code: currentYear, label: currentYear }
  ]
  // tslint:disable-next-line: radix
  for (let i = 0; i < (Number(currentYear) - year); i++) {
    yearOptions.push({ code: `${Number(currentYear) - i - 1}`, label: `${Number(currentYear) - i - 1}` })
  }
  for (let i = 0; i < yearnum; i++) {
    futureyearOptions.push({ code: `${Number(currentYear) + i + 1}`, label: `${Number(currentYear) + i + 1}` })
  }
  return { currentYear, yearOptions, futureyearOptions }
}

export function downloadFiles(url: any, params = {}) {// 下载列表
  const link = document.createElement('a')
  link.style.display = 'none'
  link.target = '_blank'
  link.href = `${url}?${Object.entries(params).reduce((a: any, b: any) => {
    return `${a}${a === '' ? '' : '&'}${b[0]}=${b[1]}`
  }, '')}`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
export function downloadOthers(file: any) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", file.url, true);
  xhr.responseType = "arraybuffer";
  xhr.onload = function () {
    if (this.status === 200) {
      var blob = new Blob([this.response], { type: "application/pdf" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = file.name;
      link.click();
    }
  };
  xhr.send();
}

export async function viewContract(allDicts: any[], jdecode: string, type = 'jdecode') { // 查看合同
  const customid = getTableRender(allDicts, 'ids_info', 'customid')
  // const appid = getTableRender(allDicts, 'ids_info', 'appid')
  // const tokenurl = `https://tids.hwwt2.com/ssologin/getToken?appid=${appid}&loginid=${store.getState().common.account}`
  const ssoToken: any = await getAccessToken()
  if (ssoToken) {
    let url = `https://tids.hwwt2.com/spa/cube/index.html?ssoToken=${ssoToken}#/main/cube/search?customid=${customid}&${type}=${jdecode}`
    if (_FRENV_ === 'online') {
      url = `https://ids.hwwt2.com/spa/cube/index.html?ssoToken=${ssoToken}#/main/cube/search?customid=${customid}&${type}=${jdecode}`
    }
    top && top.window.open(url)
  }
}

export function AuthWrapper(code: string) {
  return isHavaAuthority(_FRENV_ === 'online' ? code : true, '', true)
}


// 对象里的时间戳和moment对象相互转化
export function convertTimestampsToMoment(oldObj: any, exclude: string[]) {
  const obj = cloneDeep(oldObj)
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof moment)) {
      obj[key] = convertTimestampsToMoment(obj[key], exclude);
    } else if (typeof obj[key] === 'number' && !isNaN(obj[key]) && exclude.includes(key)) { // 判断属性值是否为时间戳
      const timestamp = obj[key];
      obj[key] = moment(timestamp * 1000); // 将时间戳乘以1000，转为Moment对象
    } else if ((obj[key] instanceof moment) && exclude.includes(key)) { // 判断属性值是否为Moment对象
      obj[key] = Math.floor(obj[key].valueOf() / 1000); // 将时间戳除以1000，转为Moment对象
    }
  }
  return obj
}

export function debounceRequest(requestFn: any, wait = 500) {
  let timer: string | number | NodeJS.Timeout | undefined;
  let resolvePromise: (value: unknown) => void;
  return (...args: any) => {
    clearTimeout(timer);

    return new Promise((resolve, reject) => {
      resolvePromise = resolve;
      timer = setTimeout(async () => {
        try {
          const result = await requestFn.apply(null, args);
          resolvePromise(result);
        } catch (error) {
          reject(error);
        }
      }, wait);
    });
  };
}

export {
  isFSA,
};
