import { Action } from '@/constants'
import {
  STANDARDDATAS,
  STANARDSELECTS,
  MASTERID,
  EDITCHARGEMODE,
  EFFECTMASTER,
  MODESTANDARD,
  BUSINESSDATAS
} from "./all";
// ACTION CREATER
const actions = {

  setStandardDatas: (type: object) => ({
    type: STANDARDDATAS,
    payload: type
  }),

  setStandardSelects: (datas: object) => ({
    type: STANARDSELECTS,
    payload: datas
  }),

  setSingleMaster: (data: object) => ({
    type: MASTERID,
    payload: data
  }),

  setEffectMaster: (data: object) => ({
    type: EFFECTMASTER,
    payload: data
  }),

  setChargeModes: (datas: object) => ({
    type: EDITCHARGEMODE,
    payload: datas
  }),

  setModeStandard: (datas: object[]) => ({
    type: MODESTANDARD,
    payload: datas
  }),

  setBusinessDatas: (data: any) => ({
    type: BUSINESSDATAS,
    payload: data
  })

};

const initData = {
  singleMaster: undefined,// 主档的记录信息
  effectDatas: undefined,// 主档生效的记录信息
  standardDatas: undefined,// 收费标准的信息
  standardSelects: undefined,// 收费标准选择收费项创建模板选中
  chargeModes: undefined, // 收费模式的信息
  modeStandardDatas: undefined,// 收费模式对应收费标准信息
  businessDatas: { businessUnitNo: "123", businessCstatus: "2", brand: "KFC", chargeModeId: "1" },// 收费项清单标识id
}
type StoreState = typeof initData
const reducer = (state = initData, action: Action): StoreState => {
  switch (action.type) {
    case STANDARDDATAS:
      return {
        ...state,
        standardDatas: action.payload
      }
    case STANARDSELECTS:
      return {
        ...state,
        standardSelects: action.payload
      }
    case MASTERID:
      return {
        ...state,
        singleMaster: action.payload
      }
    case EFFECTMASTER:
      return {
        ...state,
        effectDatas: action.payload
      }
    case EDITCHARGEMODE:
      return {
        ...state,
        chargeModes: action.payload
      }
    case MODESTANDARD:
      return {
        ...state,
        modeStandardDatas: action.payload
      }
    case BUSINESSDATAS:
      return {
        ...state,
        businessDatas: action.payload
      }
    default:
      return state
  }
}

export default reducer;
export { actions };
