import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
// import { RouteProps } from 'react-router';
// import Main from './main';
import Interface from './interface';
import Configmod from './configmod';
import Content from './content';
import Transaction from './transaction';
import WholeLog from './wholeLog';
import { Location } from 'history';
import { TestIcon } from '@/config/iconsvg';
// interface RouteConfig extends RouteProps {
//   name: string;
// }
// interface Log {
//   [propName: string]: RouteConfig
// }
export const routes = [{
  name: '日志查询',
  icon: TestIcon,
  path: '/log',
  children: [
    {
      name: '接口日志查询',
      exact: true,
      path: '/log',
      component: Interface,
      icon: TestIcon,
    },
    {
      name: '配置修改查询',
      exact: true,
      path: '/log/configmod',
      component: Configmod,
      icon: TestIcon,
    },
    {
      name: '日志内容查询',
      exact: true,
      path: '/log/content',
      component: Content,
      icon: TestIcon,
    },
    {
      name: '交易查询',
      exact: true,
      path: '/log/transaction',
      component: Transaction,
      icon: TestIcon,
    },
    {
      name: '整体日志追踪查询',
      exact: true,
      path: '/log/wholeLog',
      component: WholeLog,
      icon: TestIcon,
    }
  ]
}]

interface Rt {
  location: Location
}
// 将路由打包 (以便做代码分割 实现异步加载)
const Routes: FC<Rt> = ({ location }) => {
  return (
    <Switch location={location}>
      {
        routes[0].children.map(item => (
          <Route key={item.path as string} exact={item.exact} path={item.path} component={item.component} />
        ))
      }
    </Switch>
  );
};

export default Routes;
