// PREFIX: MODULE/VIEW
const PREFIX = 'CHARGE/REDUX';

// BUSINESS ACTIONS
export const STANDARDDATAS = `${PREFIX}/STANDARDDATAS`;
export const STANARDSELECTS = `${PREFIX}/STANARDSELECTS`;
export const MASTERID = `${PREFIX}/MASTERID`;
export const EFFECTMASTER = `${PREFIX}/EFFECTMASTER`;
// export const ALLDICT = `${PREFIX}/ALLDICT`;
export const EDITCHARGEMODE = `${PREFIX}/EDITCHARGEMODE`;
export const MODESTANDARD = `${PREFIX}/MODESTANDARD`;
export const BUSINESSDATAS = `${PREFIX}/BUSINESSDATAS`;
