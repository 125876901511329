
/**
 * 王永 2021-1-07
 * 入口
 */
// https://babeljs.io/docs/en/babel-polyfill#docsNav
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import urls from '@/config/url';
// import * as serviceWorker from './serviceWorker'; TODO:
// import { getComponents } from 'src/page/main' TODO: 模块解析
import './less/index.less';
import store from '@/store';

// getComponents()
// if (process.env.NODE_ENV === 'production') {
//   window.addEventListener('beforeunload', e => {
//     const msg = '确定要离开吗？'
//     e.returnValue = msg
//     return msg
//   }, false)
// }
const allPath = urls.reduce((bf: any, af: any) => {
  const res = af.children.reduce((b: any, a: any) => {
    return b.concat(a?.children || [])
  }, [])
  return res ? bf.concat(res) : bf
}, [])
window.addEventListener('hashchange', (e: any) => {
  if (top === window) {
    const newurl = e.newURL.split("#")[1].split("?")[0]
    const name = allPath.find((item: any) => item.path === newurl)?.name
    const domLists: any = top?.document.getElementsByClassName("ant-menu-item")
    let findDom: any
    for (let i = 0, len = domLists.length; i < len; i++) {
      if (domLists[i].innerText === name) {
        findDom = domLists[i]
        break
      }
    }
    if (findDom) {
      findDom.click()
    }
  }
}, false);
ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('app') as HTMLElement
);

// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();