import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Redux from './redux';
import Paging from './paging';
import Paging2 from './paging2';
import Iframe from './iframe';
import Iform from './form';
import { Location } from 'history';
import { TestIcon } from '@/config/iconsvg';

export const routes =
  [{
    name: '组件demo',
    icon: TestIcon,
    path: '/example',
    children: [
      {
        name: '状态管理',
        exact: true,
        path: '/example/redux',
        component: Redux,
        icon: TestIcon,
      },
      {
        name: '分页demo1',
        exact: true,
        path: '/example/paging',
        component: Paging,
        icon: TestIcon,
      },
      {
        name: '分页demo2',
        exact: true,
        path: '/example/paging2',
        component: Paging2,
        icon: TestIcon,
      },
      {
        name: 'ifame嵌入测试',
        exact: true,
        path: '/example/iframe',
        component: Iframe,
        icon: TestIcon,
      },
      {
        name: '表单测试',
        exact: true,
        path: '/example/form',
        component: Iform,
        icon: TestIcon,
      },
    ]
  }]

interface Rt {
  location: Location
}

// 将路由打包 (以便做代码分割 实现异步加载)
const Routes = ({ location }: Rt) => {
  return (
    <Switch location={location}>
      {
        routes[0].children.map(item => (
          <Route key={item.path as string} exact={item.exact} path={item.path} component={item.component} />
        ))
      }
    </Switch>
  );
};

export default Routes;
