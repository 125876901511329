import React, { useState, useMemo, ReactNode, useEffect, useCallback } from 'react';
import { Form, Button, Row, Col, Input, Popover, InputNumber } from 'antd';
import { SearchOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Rule } from 'rc-field-form/lib/interface';
import dayjs from "dayjs";
import EncInput, { Props as InputProps } from "@/components/encinput";
import EncSelect, { Props as SelectProps } from "@/components/encselect";
import EncCheckbox, { Props as CheckBoxProps } from "@/components/enccheckbox";
import EncRadio, { Props as RadioProps } from "@/components/encradio";
import EncDatePicker from '@/components/encdatePicker'
import style from './style.less';
import { FormInstance } from 'antd/lib/form';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { filterData, getSelectlabel, getSelfId } from "@/utils";
import moment from 'dayjs';
export interface Options {
  type: string,
  desc: string,
  code: string,
  label: string,
}
type Types = 'INPUT' | 'SELECT' | 'DATE' | 'DATERANGE' |
  'SEARCH' | 'SUFFIXSEARCH' | 'TEXTAREA' | 'RENDER' | 'CHECKBOX' | 'RADIO' | 'NUMBER';

export interface SearchColumns extends InputProps, SelectProps, CheckBoxProps, RadioProps {// 传参对应可选值
  type: Types,
  name: string,
  label: string | ReactNode,
  labelAlign?: 'left' | 'right';
  notlabel?: boolean,
  labelpop?: {
    content: ReactNode,
    trigger?: 'click' | 'hover',
    children?: ReactNode,
    placement?: TooltipPlacement
  },
  span?: number,// COl 所占用宽度
  offset?: number,// COl 所占用宽度
  width?: number | string,
  bordered?: boolean,// 是否包含border
  placeholder?: string,
  maxLength?: number,
  disabled?: boolean,
  filterkey?: string,// 配合主数据allDicts使用，负责过滤select option及回填的时候label的展示 
  picker?: "time" | "date" | "week" | "month" | "quarter" | "year" | undefined,
  format?: string,
  handleprops?: string[],// 这个是一个form.item对应多个变量的情况 DATERANGE/DATE
  timeFormat?: string,// 格式化方式 DATERANGE/DATE
  rules?: Rule[],// form 表单校验规则
  suffix?: ReactNode,// 场景不多，只是对于SUFFIXSEARCH的图标，默认是搜索按钮
  addonBefore?: ReactNode,
  style?: object,
  needlast?: string | undefined,// 是否展示上一次数据
  submitRules?: (datas: object, form: FormInstance<any>) => boolean,// 提交时进行的校验，是对搜索的补充
  render?: ReactNode,
  wrapperCol?: object,
  labelCol?: object,
  rangeDates?: string[] // 限制日期选择范围
  minRows?: number// TextArea 使用
  selectHasAll?: boolean,
  classname?: string,
  canceldel?: boolean,
  disabledDate?: (current: any) => boolean,
  defaultpickervalue?: any,
  precision?: number
}
export interface SearchFormProps {
  columns: SearchColumns[],
  forwardRef?: any,// 后续重新定义该值
  classNames?: string,
  hasBtn?: boolean,// 是否包含按钮组
  hasRest?: boolean,// 是否包含重置按钮
  btnSpan?: number,// 按钮组所占用空间
  initialValues?: object,
  formLayout?: 'horizontal' | 'vertical' | 'inline' | 'custom',// Todo custom 特别定制内部左右间距为72px
  selfItemLayout?: any,
  searchCallback?: (val: any) => void// 返回所有参数
  allDicts?: Options[],
  disabled?: boolean,// 这里的disabled 优先级大于columns中的
  exteriorClear?: () => void,// 特别定制，告知外部清空存储元素
  vcg?: boolean, // 字段修改自动更新
  externalFeedBack?: (val: any) => void,// 跑数据到外部
  clickClearSelectedRows?: () => void, // 查询清除选中rows
  resetSave?: boolean,// 重置保存初始值
  rowspan?: number,
  selfDom?: ReactNode,
}
const SearchForm = ({
  classNames, columns, btnSpan = 4, hasBtn = true, hasRest = true, rowspan,
  initialValues = {}, formLayout = 'horizontal', selfItemLayout = '', forwardRef, searchCallback,
  allDicts, disabled = false, exteriorClear, vcg, externalFeedBack, clickClearSelectedRows, selfDom,
  resetSave = false
}: SearchFormProps) => {
  const [form] = Form.useForm();
  const Max = 24;
  const defaultSpan = formLayout === 'vertical' ? 7 : 8;
  const [formparams, setformparams] = useState<any[]>([{}, {}]);
  const formItemLayout = useMemo(() => {
    if (hasRest && formLayout === 'horizontal') {
      return selfItemLayout ? selfItemLayout : {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      }
    }
    return {}
  }, [hasRest, formLayout, selfItemLayout])
  const [formatO, setformatO] = useState<object>({});
  const onFinish = (values: any) => {// 提交会先去校验有没有提交校验项,校验逻辑页面提供
    const result = { ...formatO, ...values }
    for (const item of columns) {// 对于handleprops的值做下清楚
      if (item.handleprops && item.canceldel !== true) {
        delete result[item.name]
      }
    }
    const isBack = columns.some(item => item.submitRules ? item.submitRules(result, form) : false)
    if (!isBack && searchCallback) {
      searchCallback(result)
    }
    clickClearSelectedRows?.()
  };
  const initSpecialValues = useCallback(() => {// 重置的时候对于特殊场景进行处理
    const obj = {}
    for (const item of columns) {
      obj[item.name] = ""
      if (item.handleprops) {
        item.handleprops.reduce((bf, af) => {
          // tslint:disable-next-line:no-null-keyword
          bf[af] = null
          return bf
        }, obj)
      }
    }
    return obj
  }, [columns])

  const setOffset = useMemo(() => {// 动态设置按钮组件排列位置
    const all = columns.reduce((a, b, i) => {
      const ofst = formLayout !== 'vertical' ? (b?.offset || 0) :
        b.offset !== undefined ? b.offset : i % 3 === 0 ? 0 : 1
      const current = (b?.span || defaultSpan) + ofst
      if (a < Max && (a + current > Max)) {
        return current
      }
      return a + current
    }, 0)
    const surplus = all % Max
    return Max - btnSpan - (surplus === 0 ? 0 : surplus)
    // return Max - btnSpan - (surplus + btnSpan > Max ? 0 : surplus === 0 ? 0 : surplus)
  }, [columns])

  const lastColumn = useMemo(() => {// 动态计算margin-bottom样式使用
    let i = 0;
    columns.reduce((a, b, idx) => {
      let ofst = 0
      if (formLayout === 'custom') {
        ofst = 0
      } else if (formLayout !== 'vertical') {
        ofst = b?.offset || 0
      } else if (b.offset !== undefined) {
        ofst = b.offset
      } else {
        ofst = idx % 3 === 0 ? 0 : 1
      }
      // const ofst = formLayout !== 'vertical' ? (b?.offset || 0) :
      // b.offset !== undefined ? b.offset : idx % 3 === 0 ? 0 : 1
      const nextVal = (b?.span || defaultSpan) + ofst
      if (Math.trunc(a / Max) !== Math.trunc((a + nextVal) / Max) &&
        (hasBtn || (!hasBtn && (idx !== columns.length - 1 ||
          (idx === columns.length - 1 && (
            nextVal === 24 || (a + nextVal) % Max) !== 0))))) {// Todo第二行针对没有按钮且搜索刚好只占满一行的情况
        i = idx
      }
      return a + nextVal
    }, 0)
    return i
  }, [columns, hasBtn])

  const handleDateRange = (dates: any, item: SearchColumns) => {
    if (item.handleprops) {
      let i = 0
      if (dates) {
        setformatO(item.handleprops.reduce((bf, af) => {
          if (item.timeFormat) {
            bf[af] = dayjs(dates[i]).format(item.timeFormat)
          } else {
            if (i === 0) {
              bf[af] = Math.floor(dayjs(dates[i]).startOf('day').valueOf() / 1000)
            } else {
              bf[af] = Math.floor(dayjs(dates[i]).endOf('day').valueOf() / 1000)
            }
          }
          i = i + 1
          return bf
        }, formatO))
      } else {
        setformatO(item.handleprops.reduce((bf, af) => {
          // tslint:disable-next-line:no-null-keyword
          bf[af] = null
          i = i + 1
          return bf
        }, formatO))
      }
    }
  }

  const disabledDate = (current: any, columnItem: SearchColumns) => {
    const rangeDates = columnItem.rangeDates;
    if (!rangeDates || rangeDates.length === 0) {
      return false;
    }
    const tooEarly = !!rangeDates[0] && moment(current).isBefore(rangeDates[0]);
    const tooLate = !!rangeDates[1] && moment(current).isAfter(rangeDates[1]);
    return tooEarly || tooLate;
  };

  const getSearchDom = (item: SearchColumns) => {
    const { type, placeholder } = item
    switch (type) {
      case 'SELECT':
        const options = allDicts?.length ?
          item?.filterkey ?
            filterData(allDicts, 'type', item?.filterkey || '') : item.options
          : item.options
        if (item?.selectHasAll && options && options.length) {
          const result = options.find((t: any) => t.code === '')
          if (!result) {
            options.unshift({ code: '', label: '全部' })
          }
        }
        return (
          <EncSelect
            {...item}
            bordered={item?.bordered}
            formvalues={formparams}
            disabled={disabled || item.disabled}
            options={options}
          />
        )
      case 'SEARCH':
        return (
          <Input.Search
            maxLength={item?.maxLength}
            bordered={item?.bordered}
            disabled={disabled || item?.disabled}
            allowClear
            width={item?.width || 'auto'}
            placeholder={placeholder || '请输入'}
          />
        )
      case 'SUFFIXSEARCH':
        return (
          <Input
            maxLength={item?.maxLength}
            bordered={item?.bordered}
            disabled={disabled || item?.disabled}
            style={{ width: item?.width || '100%' }}
            suffix={
              item?.suffix || <SearchOutlined
                style={{
                  fontSize: 20,
                  color: '#ccc',
                }}
              />
            }
            placeholder={placeholder || "支持收费项编号及名称的模糊查询，多个关键字用竖线“|”分割"}
            className="search-button"
            autoComplete="off"
          />
        )
      case 'NUMBER':
        return (
          <InputNumber
            max={item?.max}
            min={0}
            disabled={disabled || item?.disabled}
            placeholder={placeholder || '请输入'}
            precision={item?.precision !== undefined ? item?.precision : 2}
          />
        )
      case 'DATE':
        return (
          <EncDatePicker
            disabled={disabled || item?.disabled}
            defaultPickerValue={item?.defaultpickervalue}
            disabledDate={(currentDate) => item?.disabledDate ? item.disabledDate(currentDate) : false}
            allowClear
            picker={item?.picker}
            bordered={item?.bordered}
            style={{ width: item?.width || '100%' }}
            format={item.format ? item.format : item.picker === 'month' ? "YYYY/MM" : "YYYY-MM-DD"}
            onChange={date => handleDateRange(date ? [date] : date, item)}
            placeholder={placeholder || undefined}
          />
        )
      case 'DATERANGE':
        return (
          <EncDatePicker.RangePicker
            disabledDate={(currentDate) => disabledDate(currentDate, item)}
            disabled={disabled || item?.disabled}
            allowClear
            bordered={item?.bordered}
            picker={item?.picker}
            style={{ width: item?.width || '100%' }}
            format={item.format ? item.format : item.picker === 'month' ? "YYYY/MM" : "YYYY-MM-DD"}
            onChange={dates => handleDateRange(dates, item)}
          />
        )
      case 'TEXTAREA':
        return (
          <Input.TextArea
            maxLength={item?.maxLength}
            disabled={disabled || item?.disabled}
            allowClear
            bordered={item?.bordered}
            placeholder={placeholder || '请输入内容'}
            autoSize={{ minRows: item?.minRows || 3, maxRows: 5 }}
            style={{ width: item?.width || 'auto' }}
          />
        )
      case 'CHECKBOX':
        if (item.isDisableds) { // CHECKBOX数组中  哪几个不能编辑
          return (
            <EncCheckbox
              {...item}
              disabled={disabled || item?.disabled}
              options={(allDicts?.length ?
                item?.filterkey ?
                  filterData(allDicts, 'type', item?.filterkey || '') : item.options
                : item.options)?.map((itm) => { if (item.isDisableds.indexOf(itm.code) !== -1) { return { ...itm, disabled: true } } return itm; })} />
          )
        } else {
          return (
            <EncCheckbox
              {...item}
              disabled={disabled || item?.disabled}
              options={allDicts?.length ?
                item?.filterkey ?
                  filterData(allDicts, 'type', item?.filterkey || '') : item.options
                : item.options} />
          )
        }

      case 'RADIO':
        return (
          <EncRadio
            {...item}
            disabled={disabled || item?.disabled}
            // bordered={item?.bordered}
            options={allDicts?.length ?
              item?.filterkey ?
                filterData(allDicts, 'type', item?.filterkey || '') : item.options
              : item.options} />
        )
      case 'RENDER':
        return item.render ? item.render : ''
      default:
        return (
          <EncInput allowClear
            maxLength={item?.maxLength}
            bordered={item?.bordered}
            disabled={disabled || item?.disabled}
            addonBefore={item?.addonBefore}
            addonAfter={item?.addonAfter}
            width={item?.width || 'auto'}
            placeholder={placeholder || '请输入'} />
        )
    }
  }
  const popLabel = (item: SearchColumns) => {
    const { labelpop } = item
    return labelpop ? (
      <Popover
        content={labelpop.content}
        trigger={labelpop.trigger || 'click'}
        placement={labelpop.placement || 'top'}
      // visible={this.state.visible}
      // onVisibleChange={this.handleVisibleChange}
      >
        {labelpop.children || <QuestionCircleOutlined />}
      </Popover>
    ) : ''
  }
  const getFields = () => {
    const arr: number[] = []
    columns.reduce((a: any, b: any, idx: number) => {
      const sp = b?.span || defaultSpan
      if (a + sp * 1 === Max) {
        arr.push(idx)
        return 0
      }
      return a + sp * 1
    }, 0)
    let count = 0
    return columns.map((item, idx) => {
      const { selectrequest, filterkey, classname = '', ...others } = item
      let datas = []
      if (filterkey && allDicts) {
        datas = filterData(allDicts, 'type', filterkey)
      }
      if (others.options) {// 特别处理下,外部处理的options
        datas = others.options
      }
      let clsname = classname + ' '
      if (formLayout === 'custom') {
        clsname = `custom-style-${count}${idx === arr[0] ? '-end' : ''}`
        count++
        if (idx === arr[0]) {
          count = 0
          arr.shift()
        }
      }
      return (
        <Col span={item?.span || defaultSpan}
          offset={
            formLayout === 'custom' ? 0 :
              formLayout === 'vertical' ?
                (typeof item.offset === 'number' ? item.offset : idx % 3 === 0 ? 0 : 1)
                : item?.offset
          }
          className={`${clsname}`}
          key={idx}>
          <Form.Item {...others}
            className={`${item.needlast === "true" ? 'search-form-label' : ''}${formLayout === 'custom' ? ' m-b-32' : (!hasRest || idx > lastColumn - 1) ?
              btnSpan === 24 ? ' m-b-32' : ' m-b-0' : ' m-b-32'}${item.label ? '' : item.notlabel == true ? ' empty-title' : ''}`}
            label={
              item.needlast === "true" ? (
                <div className="w-100">
                  <span>{item.label}{popLabel(item)}</span>
                  <Input className='label-disabled' disabled={true} defaultValue={
                    datas.length ?
                      getSelectlabel(datas, initialValues[`label_${item.name}`]) :
                      initialValues[`label_${item.name}`]
                  } />
                </div>
              ) : (<div className="w-100">{item.label}{popLabel(item)}</div>)
            }>
            {getSearchDom(item)}
          </Form.Item>
        </Col >
      )
    });
  }

  const resetFields = () => {
    setformatO({})
    // form.resetFields()
    form.setFields(columns.map((item: any) => ({
      name: item.name,
      value: initialValues[item.name]
    })))
    exteriorClear?.()
    if (initialValues && columns) {// 重置对于默认禁用的数据防丢失
      const disabledCols = columns.filter(v => v.disabled)
      let initvals = {}
      if (!resetSave) {
        for (const item of disabledCols) {
          initvals[item.name] = initialValues[item.name]
        }
      } else {
        initvals = initialValues
      }
      if (Object.keys(initvals).length) {
        setformparams([{}, { ...initvals }])
        form.setFieldsValue({ ...initvals })
      }
    }
  }

  useEffect(() => {
    // tslint:disable-next-line:no-unused-expression
    initialValues && form.setFieldsValue({ ...initialValues });
  }, [initialValues])

  return (
    <Form form={form}
      ref={forwardRef}
      className={`${style.searchform}${classNames ? ' ' + classNames : ''}${formLayout === 'custom' ? ' custom-style' : ''}`}
      onFinish={onFinish}
      name={getSelfId()}
      // initialValues={initialValues}
      layout={formLayout === 'custom' ? 'vertical' : formLayout}
      onValuesChange={(values: any) => {
        const result = { ...formatO, ...values }
        if (vcg && searchCallback) {
          searchCallback(result)
        }
        const res = { ...formatO, ...form.getFieldsValue() }
        setformparams([values, res])
        externalFeedBack?.(res)
      }}
      {...formItemLayout}>
      <div className="search-content">
        <Row>
          {rowspan ? (
            <>
              <Col span={rowspan}>
                <Row>
                  {getFields()}
                </Row>
              </Col>
              <Col span={24 - rowspan}>
                {
                  hasBtn && (
                    <Col span={btnSpan} offset={!hasRest ? 0 : setOffset}
                      className={`flex ${formLayout === 'vertical' ? 'al-end jc-end' : 'al-start jc-center'}`}>
                      <Button className="red" htmlType="submit" type="primary">查询</Button>
                      {
                        hasRest && (
                          <Button
                            className="m-l-10"
                            onClick={resetFields}
                          >重置</Button>
                        )
                      }
                      {
                        selfDom ? selfDom : ''
                      }
                    </Col>
                  )
                }
              </Col>
            </>
          ) : (<>
            {getFields()}
            {
              hasBtn && (
                <Col span={btnSpan} offset={!hasRest ? 0 : setOffset}
                  className={`flex ${formLayout === 'vertical' ? 'al-end jc-end' : 'al-start jc-center'}`}>
                  <Button className="red" htmlType="submit" type="primary">查询</Button>
                  {
                    hasRest && (
                      <Button
                        className="m-l-10"
                        onClick={resetFields}
                      >重置</Button>
                    )
                  }
                  {
                    selfDom ? selfDom : ''
                  }
                </Col>
              )
            }
          </>)}
        </Row>
      </div>

    </Form >
  )
}

// tslint:disable-next-line:max-file-line-count
export default SearchForm