import React, { useCallback } from 'react';
import { Table } from 'antd';
import { TableProps, ColumnsType } from "antd/lib/table";
import { toThousands } from "@/utils/index";
// import { fromJS } from 'immutable';
declare module "antd/lib/table" {
  export interface ColumnType<RecordType> {
    type?: 'fee';
  }
}
export type { ColumnsType, TableProps }
const EncTable = (
  {
    columns,
    dataSource = [],
    ...others
  }: TableProps<object>
) => {
  const comformat = useCallback((item: any) => {
    if (item.type === 'fee') {
      item.align = 'right'
    }
    return {
      ...item,
      render: (t: any, row: any, idx: number) => {
        if (item.type === 'fee') {
          let result = '' // t ? toThousands(window.parseFloat(t).toFixed(2)) : '0.00'
          // if (t) {
          const r = window.parseFloat(t)
          result = window.isNaN(r) ? t : toThousands(r.toFixed(2))
          // } else {
          //   result = '0.00'
          // }
          return <span title={result}>{result}</span>
        }
        if (item.render) {
          const result = item.render(t, row, idx)
          if (typeof result === 'string') {
            return <span title={result}>{result}</span>
          }
          return <span >{result}</span>
        }
        if (item.selfRender) {
          return item.selfRender(t, row, idx)
        }
        return <span title={t}>{t}</span>
      }
    }
  }, [])
  const newColumns = columns?.map((v: any) => {// 添加公共的样式
    if (v.children) {
      v.children = v.children.map((t: any) => comformat(t))
      return v
    } else {
      return comformat(v)
    }
  })
  if (!others.rowKey) {
    dataSource.forEach((item: any, index) => {
      item.key = index;
    })
  }
  return (
    <Table
      columns={newColumns}
      dataSource={dataSource}
      {...others}
      pagination={false}
    />
  );
}

export default EncTable
