// TODO: 文件自动生成 路由懒加载
import React from 'react';
import loadable from '@loadable/component';
import Loading from 'components/loading';

let routes = [
  {
    path: '/login',
    component: loadable(
      () => import(/* webpackChunkName: "login" */ '@/modules/login'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/charge',
    component: loadable(
      () => import(/* webpackChunkName: "charge" */ '@/modules/charge'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/bill',
    component: loadable(
      () => import(/* webpackChunkName: "bill" */ '@/modules/billManagement'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/debtOffStock',
    component: loadable(
      () =>
        import(/* webpackChunkName: "debtOffStock" */ '@/modules/debtOffStock'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/billingTrack',
    component: loadable(
      () =>
        import(/* webpackChunkName: "billingTrack" */ '@/modules/billingTrack'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/income',
    component: loadable(
      () => import(/* webpackChunkName: "income" */ '@/modules/income'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/franchisees',
    component: loadable(
      () => import(/* webpackChunkName: "franchisees" */ '@/modules/paysBill'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/opera',
    component: loadable(
      () => import(/* webpackChunkName: "opera" */ '@/modules/operaMainten'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/report',
    component: loadable(
      () => import(/* webpackChunkName: "report" */ '@/modules/report'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/operation',
    component: loadable(
      () => import(/* webpackChunkName: "opera" */ '@/modules/operation'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/dashBoard',
    component: loadable(
      () => import(/* webpackChunkName: "dashBoard" */ '@/modules/dashBoard'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/dump',
    component: loadable(
      () => import(/* webpackChunkName: "dump" */ '@/modules/dumpPage'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/zhdj',
    component: loadable(
      () => import(/* webpackChunkName: "zhdj" */ '@/modules/zhdj'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/yumPaybill',
    component: loadable(
      () => import(/* webpackChunkName: "yumPaybill" */ '@/modules/bsPayBills'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/tool',
    component: loadable(
      () => import(/* webpackChunkName: "tool" */ '@/modules/tool'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/todoLists',
    component: loadable(
      () => import(/* webpackChunkName: "todo" */ '@/modules/todoList'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/jdeManagement',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "todo" */ '@/modules/jdeManagement/jadDataList'
        ),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/basicData',
    component: loadable(
      () => import(/* webpackChunkName: "basicdata" */ '@/modules/basicData'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/projectapply',
    component: loadable(
      () =>
        import(/* webpackChunkName: "projectapply" */ '@/modules/projectApply'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/recruit',
    component: loadable(
      () => import(/* webpackChunkName: "recruit" */ '@/modules/recruit'),
      {
        fallback: <Loading />,
      }
    ),
  }, {
    path: '/personchange',
    component: loadable(
      () =>
        import(/* webpackChunkName: "personchange" */ '@/modules/personchange'),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/trainingrecord',
    component: loadable(
      () =>
        import(
          /* webpackChunkName: "trainingrecord" */ '@/modules/trainingRecord'
        ),
      {
        fallback: <Loading />,
      }
    ),
  },
  {
    path: '/storeManage',
    component: loadable(
      () =>
        import(/* webpackChunkName: "storeManage" */ '@/modules/storeManage'),
      {
        fallback: <Loading />,
      }
    ),
  }, {
    path: '/associate',
    component: loadable(
      () =>
        import(/* webpackChunkName: "associate" */ '@/modules/associate'),
      {
        fallback: <Loading />,
      }
    )
  },
  {
    path: '/aiAssistantManage',
    component: loadable(
      () =>
        import(/* webpackChunkName: "storeManage" */ '@/modules/aiAssistant/router/index'),
      {
        fallback: <Loading />,
      }
    ),
  },

  {
    path: '/aiHelper',
    component: loadable(
      () =>
        import(/* webpackChunkName: "aiHelper" */ '@/modules/aiHelper'),
      {
        fallback: <Loading />,
      }
    ),
  },
];

if (_FRENV_ === 'log') {
  // 给后端用
  routes = [
    {
      path: '/log',
      component: loadable(
        () => import(/* webpackChunkName: "log" */ '@/modules/log'),
        {
          fallback: <Loading />,
        }
      ),
    },
  ];
}

if (_FRENV_ === 'dev') {
  routes = routes.concat([
    {
      path: '/example',
      component: loadable(
        () => import(/* webpackChunkName: "example" */ 'modules/example'),
        {
          fallback: <Loading />,
        }
      ),
    },
    {
      path: '/iframe',
      component: loadable(
        () => import(/* webpackChunkName: "iframe" */ '@/modules/iframe'),
        {
          fallback: <Loading />,
        }
      ),
    },
    {
      path: '/log',
      component: loadable(
        () => import(/* webpackChunkName: "log" */ 'modules/log'),
        {
          fallback: <Loading />,
        }
      ),
    },
  ]);
}

export default routes;
