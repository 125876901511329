import React from 'react';
import { Form, Select } from 'antd';
const { Option } = Select;

interface IProps {
  name?: string,
  label?: string,
  required?: boolean,
  onChange: ((value: any, option: any) => void) | undefined,
  selectList: any[],
  placeholder?: string,
  width?: string | number,
  noStyle?: boolean,
  defaultValue?: any[],
  disabled?: boolean,
  value?: any[],
  mode?: any,
  allowClear?: boolean
}

function EnSelect(props: IProps) {
  const {
    name,
    label,
    onChange,
    selectList,
    placeholder = "请选择",
    width = "auto",
    noStyle = false,
    required,
    defaultValue,
    disabled,
    value,
    mode,
    allowClear = true,
  } = props;
  return (
    <>
      {!noStyle && <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required,
          },
        ]}
        noStyle={noStyle}
      >
        <Select
          placeholder={placeholder}
          onChange={onChange}
          allowClear={allowClear}
          style={{ width }}
          defaultValue={defaultValue}
          disabled={disabled}
          value={value}
          mode={mode}
        >
          {
            selectList.map((item, index) => (
              <Option key={item.id || index} value={item.value}>{item.name}</Option>
            ))
          }
        </Select>
      </Form.Item>
      }
      {
        noStyle &&
        <>
          <div
            className="ant-col ant-form-item-label"
          >
            {required && <span className="required">*</span>}
            {label && <label className="" title={label}>{label}</label>}
          </div>
          <Select
            placeholder={placeholder}
            onChange={onChange}
            allowClear={allowClear}
            style={{ width }}
            defaultValue={defaultValue}
            disabled={disabled}
            value={value}
            mode={mode}
          // className={required ? 'required' : ''}
          >
            {
              selectList.map((item, index) => (
                <Option key={item.id || index} value={item.value}>{item.name}</Option>
              ))
            }
          </Select>
        </>
      }
    </>

  );
}

export default EnSelect;
