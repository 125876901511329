import { Action } from '@/constants'
import {
  TABIDX, SEARCHPARAMS
} from "./all";
// ACTION CREATER
const actions = {

  setTabIdx: (type: string) => ({
    type: TABIDX,
    payload: type
  }),

  setRecordRqtparams: (type: object) => ({
    type: SEARCHPARAMS,
    payload: type
  }),
};

const initData = {
  tabIdx: '',// 报表默认值
  recordRqtparams: {}// 记录请求参数
}
type StoreState = typeof initData
const reducer = (state = initData, action: Action): StoreState => {
  switch (action.type) {
    case TABIDX:
      return {
        ...state,
        tabIdx: action.payload
      }
    case SEARCHPARAMS:
      return {
        ...state,
        recordRqtparams: action.payload
      }
    default:
      return state
  }
}

export default reducer;
export { actions };
