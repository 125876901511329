import * as React from 'react';

// 创建类型接口
export interface IProps {
  value: number,
  onIncrement: (count: number) => any,
  onDecrement: (count: number) => any
}

export default class Counter extends React.PureComponent<IProps> { // 使用接口代替 PropTypes 进行类型校验
  public render() {
    const { value } = this.props;
    return (
      <p>
        Clicked: { value} times
        <br />
        <br />
        <button onClick={this.inc} style={{ marginRight: 20 }}> +  </button>
        <button onClick={this.dec}> - </button>
      </p>
    )
  }
  public inc = () => {
    const { value, onIncrement } = this.props;
    onIncrement(value + 1)
  }
  public dec = () => {
    const { value, onDecrement } = this.props;
    onDecrement(value - 1)
  }
}
