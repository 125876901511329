import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
interface ExteriorOption {
  code: string,
  label: string,
  disabled?: boolean
}
interface Option {
  label: string;
  value: string;
  disabled?: boolean
}
export interface Props {
  // defaultValue?: string[],
  width?: number | string,
  disabled?: boolean,
  options?: ExteriorOption[],
  value?: any;// 目前form表单联动使用
  onChange?: (value: any) => void;// 目前form表单联动使用
}
const EncCheckbox = ({
  disabled,
  width,
  options = [],
  // defaultValue,
  value,
  onChange
}: Props) => {
  const [SelectOptions, setSelectOptions] = useState<Option[]>([]);
  useEffect(() => {
    if (options && options.length) {
      setSelectOptions(options.map(v => ({
        ...v,
        value: v.code
      })))
    }
  }, [options]);
  const triggerChange = (val: any) => {
    window.console.log(val)
    onChange?.(val);
  }

  return (
    <Checkbox.Group
      disabled={disabled}
      value={value}
      onChange={triggerChange}
      options={SelectOptions}
      style={{ width: width || '100%' }} />
  )
}

export default EncCheckbox
